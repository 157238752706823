import { CommonEventTypes, CourseModel, Translations } from '@lerna-core';
import * as Yup from 'yup';
import {
  EVENT_TYPE,
  getTextMaxLengthValidationMessage,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import {
  GranulePayFormInitialValues,
  GranulePayFormValidationSchema,
} from './granulePayForm.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';

export const getGranulePayFormInitialValues =
  (): GranulePayFormInitialValues => ({
    withPromoCode: false,
    promoCode: '',
  });

export function getGranulePayFormValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean
): GranulePayFormValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected
      ? {
          withPromoCode: Yup.boolean().optional(),
          promoCode: Yup.string().when('withPromoCode', {
            is: true,
            then: (promoCode) =>
              promoCode
                .required(translations?.input_required_error_label)
                .max(255, getTextMaxLengthValidationMessage(255, translations))
                .trim(),
          }),
        }
      : {}),
  };

  return Yup.object().shape(validationObject);
}

export const getFormSubmitEventValues = (
  course: CourseModel,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean
): OrderFormEventsValues[] => {
  const eventValueOptions = [
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
  ];

  switch (true) {
    case course.isFree:
      eventValueOptions.unshift(OrderFormEventsValues.granuleFree);
      eventValueOptions.push(OrderFormEventsValues.priceNull);
      break;
    case course.isFreeByDiscount:
      eventValueOptions.unshift(OrderFormEventsValues.granuleFree);
      eventValueOptions.push(OrderFormEventsValues.discount100);
      break;
    case course.isFreeByBonuses:
      eventValueOptions.unshift(OrderFormEventsValues.granuleFree);
      eventValueOptions.push(OrderFormEventsValues.discountPoints);
      break;
    case paymentContent === UserOrderFormPaymentContentTypes.onlinePay:
      eventValueOptions.unshift(OrderFormEventsValues.granulePaid);
      eventValueOptions.push(OrderFormEventsValues.paymentOnline);
      break;
    case paymentContent === UserOrderFormPaymentContentTypes.consultation:
      eventValueOptions.unshift(OrderFormEventsValues.granulePaid);
      eventValueOptions.push(OrderFormEventsValues.consultation);
      break;
  }

  return eventValueOptions;
};

export const getGranulePayFormPaymentEventValues = (
  showPaymentTabs: boolean
): OrderFormEventsValues[] => [
  OrderFormEventsValues.granulePaid,
  showPaymentTabs
    ? OrderFormEventsValues.withTabs
    : OrderFormEventsValues.noTabs,
  OrderFormEventsValues.paymentOnline,
];

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = getFormSubmitEventValues(
    course,
    paymentContent,
    showPaymentTabs
  );
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};

export const pushGranulePayFormPaymentEvent = (
  course: CourseModel,
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  const eventValueOptions =
    getGranulePayFormPaymentEventValues(showPaymentTabs);

  orderFormEvent(
    `${OrderFormEvents.formEnrollGoToOnlinePay}`,
    eventValueOptions,
    CommonEventTypes.coursesEvents,
    eventPosition,
    course
  );
};
