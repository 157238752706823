import {
  EVENT_TYPE,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import { CommonEventTypes, CourseModel } from '@lerna-core';
import { GuestOrderFormSubmitStatusTypes } from './guestOrderForm.model';

export const getFormSubmitEventValues = (
  course: CourseModel
): OrderFormEventsValues[] => {
  const eventValueOptions = [OrderFormEventsValues.noTabs];

  switch (true) {
    case course.isFree:
      eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
      eventValueOptions.push(OrderFormEventsValues.priceNull);
      break;
    case course.isFreeByDiscount:
      eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
      eventValueOptions.push(OrderFormEventsValues.discount100);
      break;
    default:
      eventValueOptions.unshift(OrderFormEventsValues.paidCourse);
      eventValueOptions.push(OrderFormEventsValues.consultation);
      break;
  }

  return eventValueOptions;
};

export const pushFormSubmitStatusEvent = (
  type: GuestOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = getFormSubmitEventValues(course);
  const eventType =
    type === GuestOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
