var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ZwxzFfC0CNZpO_jNa2tEv"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { publicConfig } from '@lerna-core';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: publicConfig.SENTRY_DSN,
  tracesSampleRate: 1.0,
  debug: false,
  environment: publicConfig?.NODE_ENV,
});

Sentry.setTag('APP_NAME', publicConfig.APP_NAME);
Sentry.setTag('REGION', publicConfig.REGION);
Sentry.setTag('LOCATION', publicConfig.LOCATION);
