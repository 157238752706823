import {
  dateService,
  objectService,
  publicConfig,
  RequestClient,
} from '@lerna-core';

export class GoogleSheetLoggerClient extends RequestClient<any, any, any> {
  requestParameters: any;
  path = publicConfig.GOOGLE_SHEET_ORDERS_PATH;

  constructor(requestParams: any) {
    super(
      'https://script.google.com/macros/s/',
      '',
      {
        'Content-Type': `multipart/form-data`,
      },
      { withCredentials: false }
    );
    this.requestParameters = new FormData();
    const flatRequestParams = objectService.deepFlattenToObject(requestParams);
    for (const key in flatRequestParams) {
      this.requestParameters.append(key, flatRequestParams[key]);
    }

    const date = new Date();
    this.requestParameters.append(
      'formGoogleSheetName',
      `${dateService.formatMonthNumberToString(
        date.getMonth()
      )} ${date.getFullYear()}`
    );
  }
}
