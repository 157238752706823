import { requestUserSuccess, setAcceptLanguage, useUser } from '@lerna-core';
import { setSecureHeadersProps } from '@services';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '@store/store.hooks';
import { coursesPageLazyClientProvider } from '../courses.clientProvider';
import { getNextCoursesPageAggregateLink } from '../courses.helper';
import { useCoursesPageContext } from '../coursesPage';
import { getCoursesPaginationData } from './coursesPagination.helper';
import {
  CoursesPaginationContainerProps,
  CoursesPaginationHookModel,
} from './coursesPagination.model';

export const useCoursesPagination = ({
  isLoading,
  handleClick,
  handleSuccess,
}: CoursesPaginationContainerProps): CoursesPaginationHookModel => {
  const dispatch = useAppDispatch();
  const {
    translations,
    pagination,
    additionalColors: { buttonAdditionalColors },
  } = useCoursesPageContext();

  const router = useRouter();
  const { user } = useUser();

  const [currentPageLink, setCurrentPageLink] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(
    pagination.currentPage || 1
  );
  const [nextLink, setNextLink] = useState<string>(pagination.links.next || '');

  const coursesPaginationData = getCoursesPaginationData(
    pagination,
    pageNumber,
    translations
  );

  const handleLoadMore = async (): Promise<void> => {
    if (!isLoading && currentPageLink != nextLink && nextLink != '') {
      handleClick();
      const newLink = getNextCoursesPageAggregateLink(
        nextLink,
        user?.career,
        router
      );
      const response = await coursesPageLazyClientProvider(newLink, {
        ...setAcceptLanguage(router.locale),
        ...setSecureHeadersProps(),
      });
      const hasNextLink = response?.pagination.links.next || '';
      setCurrentPageLink(nextLink);
      setPageNumber(pageNumber + 1);
      if (response.user) {
        dispatch(requestUserSuccess(response.user));
      }
      handleSuccess(response.courses);
      setNextLink(hasNextLink);
    }
  };

  useEffect(() => {
    setNextLink(pagination.links.next || '');
    setPageNumber(pagination.currentPage || 1);
  }, [pagination]);

  return {
    buttonAdditionalColors,
    coursesPaginationData,
    handleLoadMore,
    isLoading,
  };
};
