import styled, { css } from 'styled-components';
import { breakpoint, positionSticky } from '../../../styles';
import { MenuSwitcherStyled } from '../../menu';
import { PRESETS } from '../../../services';

export const IHeader = styled.div`
  ${positionSticky()};
  top: 0;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_CONTENT};

  border-bottom-style: solid;
  border-bottom-color: ${({ theme }): string => theme.COLOR.BLACK_12};
  border-bottom-width: 1px;

  padding: 16px 40px;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding: 12px 16px;
      `,
      theme.IS_MOBILE
    )}
`;

export const IHeaderBreadcrumbs = styled.div`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const IHeaderTop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1920px;

  ${MenuSwitcherStyled} {
    margin-right: 32px;
  }

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        justify-content: flex-end;
      `,
      theme.IS_MOBILE
    )}
`;

export const IHeaderLogo = styled.div`
  height: ${PRESETS.logoCompany.opts.h}px;
  width: ${PRESETS.logoCompany.opts.w}px;

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: ${PRESETS.logoCompanySmall.opts.h}px;
        width: ${PRESETS.logoCompanySmall.opts.w}px;
      `,
      theme.IS_MOBILE
    )}
`;
