import {
  CourseUrlParams,
  getCoursePageHref,
  Nullable,
  QueryParams,
} from '@lerna-core';
import { GetCourseHrefProps } from '../models/course.model';
import { NextRouter } from 'next/router';
import { pageUrls } from '@constants';

export const getCourseHref = ({
  course,
  router,
  basePath,
  initiator,
}: GetCourseHrefProps): Nullable<string> => {
  const urlParams: CourseUrlParams = {};
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;
  const promoCodeQuery = router.query[QueryParams.promoCode] as string;

  if (discountProgramQuery) {
    urlParams[QueryParams.discountProgram] = discountProgramQuery;
  }
  if (promoCodeQuery) {
    urlParams[QueryParams.promoCode] = promoCodeQuery;
  }
  if (initiator) {
    urlParams[QueryParams.iframeInitiator] = initiator;
  }

  return getCoursePageHref({
    course,
    router,
    urlParams,
    basePath,
  });
};

export const getCoursesPath = (
  router: NextRouter,
  withoutPromo = false
): string => {
  const discount = router.query[QueryParams.discountProgram] as string;
  const iframeInitiator = router.query[QueryParams.initiator] as string;

  switch (true) {
    case !!discount && !!iframeInitiator && !withoutPromo: {
      return pageUrls.iframePromo
        .replace(`[${QueryParams.initiator}]`, iframeInitiator)
        .replace(`[${QueryParams.discountProgram}]`, discount);
    }
    case !!iframeInitiator: {
      return pageUrls.iframeCourses.replace(
        `[${QueryParams.initiator}]`,
        iframeInitiator
      );
    }
    case !!discount && !withoutPromo: {
      return pageUrls.promo.replace(
        `[${QueryParams.discountProgram}]`,
        discount
      );
    }
    default:
      return pageUrls.courses;
  }
};
