import { CommonEventTypes, CourseModel } from '@lerna-core';
import {
  EVENT_TYPE,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import { UserOrderFormSubmitStatusTypes } from '../../userOrderForm.model';

export const getFormSubmitStatusEvent = (
  course: CourseModel
): OrderFormEventsValues[] => {
  const eventValueOptions = [OrderFormEventsValues.noTabs];

  switch (true) {
    case !!course.subscriptionAccess:
      eventValueOptions.unshift(OrderFormEventsValues.granuleSubscription);
      eventValueOptions.push(OrderFormEventsValues.default);
      break;
    case course.isFree:
      eventValueOptions.unshift(OrderFormEventsValues.granuleFree);
      eventValueOptions.push(OrderFormEventsValues.priceNull);
      break;
    case course.isFreeByDiscount:
      eventValueOptions.unshift(OrderFormEventsValues.granuleFree);
      eventValueOptions.push(OrderFormEventsValues.discount100);
      break;
    case course.isFreeByBonuses:
      eventValueOptions.unshift(OrderFormEventsValues.granuleFree);
      eventValueOptions.push(OrderFormEventsValues.discountPoints);
      break;
  }

  return eventValueOptions;
};

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = getFormSubmitStatusEvent(course);
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
