import { AccordionSpoilerIconVersionModel } from '../accordion.model';
import React, { ReactElement } from 'react';
import {
  IconArrowComponent,
  IconMinusComponent,
  IconPlusComponent,
} from '../../icons';

export const getAccordionSpoilerIcon = (
  spoilerIconVersion: AccordionSpoilerIconVersionModel,
  isOpened: boolean
): ReactElement => {
  switch (spoilerIconVersion) {
    case 'v1':
      return <IconArrowComponent width={24} height={24} />;
    case 'v2':
      if (isOpened) {
        return (
          <IconMinusComponent width={24} height={24} strokeColor="#FFFFF" />
        );
      }

      return <IconPlusComponent width={24} height={24} strokeColor="#FFFFF" />;
  }
};
