import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { FiltersClearAllContainerProps } from './filtersClearAll.model';
import { FiltersClearAllContainerStyled } from './filtersClearAll.styled';
import { handleClearFilters, handleSetSearchTerm } from '@lerna-core';
import { getCoursesPath } from '@helpers';

export const FiltersClearAllContainer = ({
  children,
  handleClear,
}: FiltersClearAllContainerProps): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();
  const defaultPathname = getCoursesPath(router, true);

  const handleClick = (): void => {
    if (handleClear) {
      handleClear();
    }
    dispatch(handleSetSearchTerm(''));
    dispatch(handleClearFilters());
    router.push(defaultPathname, undefined, { scroll: false });
  };

  return (
    <FiltersClearAllContainerStyled onClick={handleClick}>
      {children}
    </FiltersClearAllContainerStyled>
  );
};
