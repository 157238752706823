import {
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  Nullable,
} from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../../coursesPage';
import { EmptyClearButtonStyled } from './emptyClearButton.styled';
import { FiltersClearAllContainer } from '@features/common/filters';

export const EmptyClearButtonContainer = (): Nullable<ReactElement> => {
  const { translations } = useCoursesPageContext();

  return (
    <EmptyClearButtonStyled>
      <FiltersClearAllContainer>
        <ButtonContainer
          color={ButtonColor.default}
          size={ButtonSize.small}
          title={translations?.clear_filters_button_label}
          borderRadius={100}
        />
      </FiltersClearAllContainer>
    </EmptyClearButtonStyled>
  );
};
