import { CourseItemProgressItemModel } from './courseItemProgressItem';
import { Nullable, TranslationModel } from '../../../../models';
import {
  CourseItemAccessModel,
  CourseItemProgressModel,
} from '../courseItem.model';
import { VendorEnum } from '../../../vendors';

export const getProgressItems = (
  vendorSlug: Nullable<VendorEnum>,
  progress: CourseItemProgressModel,
  { isHomeworkAvailable }: CourseItemAccessModel,
  translations: TranslationModel
): CourseItemProgressItemModel[] => {
  switch (vendorSlug) {
    case VendorEnum.skillfactory:
    case VendorEnum.geekbrains:
      return [
        {
          title: translations?.progress_lessons,
          count: progress.totalCount,
          countDone: progress.totalDoneCount,
        },
      ];
    default: {
      const items = [
        {
          title: translations?.progress_videos,
          count: progress.videosCount,
          countDone: progress.videosDoneCount,
        },
        {
          title: translations?.progress_tests,
          count: progress.testsCount,
          countDone: progress.testsDoneCount,
        },
      ];

      if (isHomeworkAvailable) {
        items.splice(0, 0, {
          title: translations?.progress_modules,
          count: progress.blocksCount || progress.topicsCount,
          countDone: progress.blocksDoneCount || progress.topicsDoneCount,
        });
        items.splice(2, 0, {
          title: translations?.progress_practical_works,
          count: progress.homeworksCount,
          countDone: progress.homeworksDoneCount,
        });
        items.splice(4, 0, {
          title: translations?.progress_final_works,
          count: progress.diplomasCount,
          countDone: progress.diplomasDoneCount,
        });
      }

      return items;
    }
  }
};
