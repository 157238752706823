import React, { ReactElement } from 'react';
import { GoToCourseEvent } from '../listItem.analytics';
import { ListItemWholeLinkContainerProps } from './listItemWholeLink.model';
import { ListItemWholeLinkContainerStyled } from './listItemWholeLink.styled';

export const ListItemWholeLinkContainer = ({
  handleGoToCourse,
  courseHref,
}: ListItemWholeLinkContainerProps): ReactElement => {
  const onClick = (): void => {
    handleGoToCourse(GoToCourseEvent.goToCourseCard);
  };

  return (
    <ListItemWholeLinkContainerStyled onClick={onClick} href={courseHref} />
  );
};
