import { FastLinkModel, FastLinkWithActiveModel } from './fastLinks.model';
import { Nullable, TranslationModel } from '../../models';
import { urlService } from '../../services';
import { FiltersSlugEnum } from '../filters';
import { ParsedUrlQuery } from 'querystring';

export const getLinksWithAllCategories = (
  links: FastLinkModel[],
  fastLinkQuery: string,
  translations: TranslationModel
): FastLinkWithActiveModel[] => {
  const allCategoryLink = {
    ...allCategoryFastLink(translations?.all_categories),
    isActive: !fastLinkQuery,
  };
  const linksWithActive = links.map((link) => ({
    isActive: link.slug === fastLinkQuery,
    ...link,
  }));

  return [allCategoryLink, ...linksWithActive];
};

export const getFastLinkHref = (fastLink: string, path: string): string => {
  return urlService.normalizeUrl(`${path}/${fastLink}`);
};

export const allCategoryFastLink = (title: string): FastLinkModel => ({
  title,
  slug: '',
  options: null,
  isVisible: true,
});

export const getActiveLinkTitle = (
  links: FastLinkWithActiveModel[],
  fastLinkQuery: string
): string | null => {
  const activeLink = links.find((link) => link.slug === fastLinkQuery);

  return activeLink ? activeLink.title : null;
};

export const getCompositeFastLink = (
  fastLinks: FastLinkModel[],
  query: ParsedUrlQuery
): Nullable<FastLinkModel> =>
  fastLinks.find((fastLink) => {
    if (
      !fastLink.options ||
      Object.keys(fastLink.options).length !== Object.keys(query).length
    ) {
      return;
    }

    return Object.keys(query).every((key) => {
      const queryArray = (query[key] as string).split(',');
      const fastLinkOptions = fastLink.options?.[key as FiltersSlugEnum];

      if (fastLinkOptions?.length !== queryArray.length) {
        return false;
      }

      return queryArray.every((value: string) => {
        if (fastLinkOptions) {
          return fastLinkOptions.some((optionValue) => optionValue === value);
        }

        return false;
      });
    });
  }) || null;
