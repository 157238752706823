import React, { ReactElement } from 'react';

import { AccordionContentStyled } from './accordionContent.styled';
import { AccordionContentContainerProps } from './accordionContent.model';

export const AccordionContentContainer = ({
  content,
  isOpened,
  version,
}: AccordionContentContainerProps): ReactElement | null => {
  if (content) {
    return (
      <AccordionContentStyled
        dangerouslySetInnerHTML={{ __html: content }}
        isOpened={isOpened}
        version={version}
      />
    );
  }

  return null;
};
