import React, { ReactElement } from 'react';
import { PopupFooterContainerStyled } from './popupFooter.styled';
import { PopupFooterContainerProps } from './popupFooter.model';

export const PopupFooterContainer = ({
  children,
  styleProps,
  isMobile,
  border,
  colorMode,
}: PopupFooterContainerProps): ReactElement => {
  return (
    <PopupFooterContainerStyled
      border={border}
      isMobile={isMobile}
      styleProps={styleProps}
      colorMode={colorMode}
    >
      {children}
    </PopupFooterContainerStyled>
  );
};
