import styled from 'styled-components';
import { FiltersClearAllContainerStyled } from '../../../common/filters/filtersClearAll/filtersClearAll.styled';

export const FiltersClearContainerStyled = styled.div`
  color: ${({ theme }): string => theme.COLOR.PURPLE};

  ${FiltersClearAllContainerStyled} {
    cursor: pointer;
    display: inline-block;
  }
`;
