import { pageUrls } from '@constants';
import { FooterContainer } from '@features/common/footer';
import { HeaderContainer } from '@features/common/header';
import { CoursesPageContextProvider } from '@features/courses/coursesPage';
import {
  BackgroundContainer,
  browserService,
  ContentWrapperStyled,
  DeviceInfoContext,
  PageWrapperContainer,
  urlService,
} from '@lerna-core';
import { getBackgroundColors } from '@styles';
import React, { ReactElement, useContext } from 'react';
import { PageNotFoundContainerProps } from './pageNotFound.model';
import {
  PageNotFoundFilterTitleStyled,
  PageNotFoundLinkContainerStyled,
  PageNotFoundLinkStyled,
  PageNotFoundStyled,
  PageNotFoundTextStyled,
  PageNotFoundTitleStyled,
} from './pageNotFound.styled';
import { PageNotFoundLinksContainer } from './pageNotFoundLinks';

export const PageNotFoundContainer = ({
  coursesData,
}: PageNotFoundContainerProps): ReactElement => {
  const {
    page_not_found_title,
    error_cod_title,
    return_to_main_segment_return,
    return_to_main_segment_to_main,
    return_to_main_segment_or_open,
    return_to_main_segment_courses_list,
    courses_by_direction_title,
  } = coursesData.translations;
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const mainPageHref = urlService.normalizeUrl(pageUrls.courses);

  const Header = (
    <HeaderContainer
      hasSticky={false}
      showFilters={false}
      showSearch={false}
      translations={coursesData?.translations}
      company={coursesData?.company}
    />
  );

  const Content = (
    <>
      <BackgroundContainer {...getBackgroundColors(null, false)} />
      <PageNotFoundStyled>
        <ContentWrapperStyled>
          <PageNotFoundTitleStyled>
            {page_not_found_title}
          </PageNotFoundTitleStyled>
          <PageNotFoundTextStyled>{error_cod_title} 404</PageNotFoundTextStyled>
          <PageNotFoundLinkContainerStyled>
            <PageNotFoundTextStyled>
              {return_to_main_segment_return}{' '}
            </PageNotFoundTextStyled>
            <PageNotFoundLinkStyled href={mainPageHref}>
              {return_to_main_segment_to_main}
            </PageNotFoundLinkStyled>
            <PageNotFoundTextStyled>
              {' '}
              {return_to_main_segment_or_open}{' '}
            </PageNotFoundTextStyled>
            <PageNotFoundLinkStyled href={mainPageHref}>
              {return_to_main_segment_courses_list}
            </PageNotFoundLinkStyled>
          </PageNotFoundLinkContainerStyled>
          <PageNotFoundFilterTitleStyled>
            {courses_by_direction_title}
          </PageNotFoundFilterTitleStyled>
          <PageNotFoundLinksContainer coursesData={coursesData} />
        </ContentWrapperStyled>
      </PageNotFoundStyled>
    </>
  );

  const Footer = (
    <FooterContainer
      translations={coursesData.translations}
      footerImages={coursesData?.footer.images || []}
    />
  );

  return (
    <CoursesPageContextProvider props={coursesData}>
      <PageWrapperContainer
        isMobile={isMobile}
        header={Header}
        content={Content}
        footer={Footer}
      />
    </CoursesPageContextProvider>
  );
};
