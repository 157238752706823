import {
  SEGMENT_COLOR_PURPLE_100,
  SEGMENT_COLOR_PURPLE_300,
  SEGMENT_COLOR_PURPLE_500,
  SEGMENT_COLOR_WHITE,
} from './wheelOfFortune.constants';
import { FortuneSegmentModel } from './wheelOfFortune.model';

export const segmentsRu: FortuneSegmentModel[] = [
  {
    text: 'Курс «Здоровая самооценка»',
    color: SEGMENT_COLOR_PURPLE_500,
    id: '1',
  },
  {
    text: 'Промокод от Литрес',
    color: SEGMENT_COLOR_WHITE,
    id: '2',
  },
  {
    text: 'Промокод от Fitmost',
    color: SEGMENT_COLOR_PURPLE_500,
    id: '3',
  },
  {
    text: 'Промокод на 20\xa0000\xa0руб',
    color: SEGMENT_COLOR_WHITE,
    id: '4',
  },
  {
    text: 'Промокод на 30\xa0000\xa0руб',
    color: SEGMENT_COLOR_PURPLE_500,
    id: '5',
  },
  {
    text: 'Гайд по нейросетям',
    color: SEGMENT_COLOR_WHITE,
    id: '6',
  },
];

export const segmentsBy: FortuneSegmentModel[] = [
  {
    text: 'Курс «Здоровая самооценка»',
    color: SEGMENT_COLOR_PURPLE_300,
    id: '12',
  },
  {
    text: 'Промокод от\xa0Литрес',
    color: SEGMENT_COLOR_PURPLE_100,
    id: '13',
  },
  {
    text: 'Промокод на 700\xa0BYN',
    color: SEGMENT_COLOR_WHITE,
    id: '14',
  },
  {
    text: 'Промокод на 1000\xa0BYN',
    color: SEGMENT_COLOR_PURPLE_100,
    id: '15',
  },
  {
    text: 'Гайд по нейросетям',
    color: SEGMENT_COLOR_WHITE,
    id: '16',
  },
];

export const segmentsKz: FortuneSegmentModel[] = [
  {
    text: 'Курс «Здоровая самооценка»',
    color: SEGMENT_COLOR_PURPLE_300,
    id: '17',
  },
  {
    text: 'Промокод от\xa0Литрес',
    color: SEGMENT_COLOR_PURPLE_100,
    id: '18',
  },
  {
    text: 'Промокод на 70\xa0000\xa0₸',
    color: SEGMENT_COLOR_WHITE,
    id: '19',
  },
  {
    text: 'Промокод на 100\xa0000\xa0₸',
    color: SEGMENT_COLOR_PURPLE_100,
    id: '20',
  },
  {
    text: 'Гайд по нейросетям',
    color: SEGMENT_COLOR_WHITE,
    id: '21',
  },
];

export const segmentsUz: FortuneSegmentModel[] = [
  {
    text: 'Курс «Здоровая самооценка»',
    color: SEGMENT_COLOR_PURPLE_300,
    id: '7',
  },
  {
    text: 'Промокод от\xa0Литрес',
    color: SEGMENT_COLOR_PURPLE_100,
    id: '8',
  },
  {
    text: 'Промокод на 2\xa0млн\xa0cум',
    color: SEGMENT_COLOR_WHITE,
    id: '9',
  },
  {
    text: 'Промокод на 3\xa0млн\xa0сум',
    color: SEGMENT_COLOR_PURPLE_100,
    id: '10',
  },
  {
    text: 'Гайд по нейросетям',
    color: SEGMENT_COLOR_WHITE,
    id: '11',
  },
];
