import React, { ReactElement } from 'react';
import { ResetItemContainerProps } from './resetItem.model';
import { getNewQuery } from './resetItem.helper';
import { IconStyled, ResetItemStyled } from './resetItem.styled';
import {
  filterRouterService,
  getCompositeFastLink,
  getFastLinkHref,
  getFilters,
  IconCloseComponent,
  pushDataLayer,
  urlService,
  useFastLinksContext,
  useTheme,
} from '@lerna-core';
import { useRouter } from 'next/router';
import { FilterEvent, getMainFilterEventData } from '../../filters.analytics';
import { useSelector } from 'react-redux';

export const ResetItemContainer = ({
  filter,
  itemValue,
  itemSlug,
  coursesPath,
  currentOption,
  isMobileType,
  customFilterColor,
}: ResetItemContainerProps): ReactElement => {
  const theme = useTheme();
  const router = useRouter();
  const filters = useSelector(getFilters);
  const fastLinks = useFastLinksContext();

  const iconCloseColor = isMobileType
    ? theme.COLOR.WHITE
    : theme.COLOR.TEXT[100];

  const resetFilter = (): void => {
    const newQuery = getNewQuery(
      {
        ...router,
        query: filterRouterService.getNewRouteFromFilters(router, filters),
      },
      filter,
      itemSlug
    );

    if (!isMobileType) {
      pushDataLayer(
        getMainFilterEventData(
          FilterEvent.constructorFiltersMain,
          filter,
          currentOption,
          true
        )
      );
    }

    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    if (fastLink) {
      router.push(getFastLinkHref(fastLink.slug, coursesPath), undefined, {
        scroll: false,
      });
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(coursesPath, router, newQuery),
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <>
      <ResetItemStyled
        customFilterColor={customFilterColor}
        isMobileType={isMobileType}
        onClick={resetFilter}
      >
        {itemValue}
        <IconStyled>
          <IconCloseComponent
            width={14}
            height={14}
            strokeColor={iconCloseColor}
            strokeWidth="3"
          />
        </IconStyled>
      </ResetItemStyled>
    </>
  );
};
