import { CurrenciesState } from './currencies.model';
import { RootState } from '../../models';
import { createSelector } from 'reselect';

export const getCurrencies = createSelector(
  (state: RootState<CurrenciesState>) => state.currencies,
  (reducer: CurrenciesState) => reducer.currencies
);

export const getBaseCurrency = createSelector(
  (state: RootState<CurrenciesState>) => state.currencies,
  (reducer: CurrenciesState) =>
    reducer.currencies.find(({ isBase }) => isBase) || reducer.currencies[0]
);
