import { useDiscountProgram } from '@features/common/discountProgram';
import { SeoTextContainer } from '@features/common/seoText';
import { Nullable } from '@lerna-core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useCoursesPageContext } from '../coursesPage';
import {
  checkCollapseSeoText,
  getIsCoursesSeoHidden,
} from './coursesSeo.helper';
import * as S from './coursesSeo.styled';
import { SeoTextShowMoreContainer } from './seoTextShowMore';
import { SeoTextImageContainer } from './seoTextImage';

export const CoursesSeoTextContainer = (): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const { isDiscountProgramPage } = useDiscountProgram();

  const ref = useRef<Nullable<HTMLDivElement>>(null);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [showControls, setShowControls] = useState<boolean>(false);

  const seoText = coursesContext.seo.text;
  const translations = coursesContext.translations;
  const isCoursesSeoHidden = getIsCoursesSeoHidden(
    coursesContext.seo,
    coursesContext.user,
    isDiscountProgramPage
  );

  useEffect(() => {
    if (ref?.current) {
      const needToCollapse = checkCollapseSeoText(ref.current);
      setIsCollapsed(needToCollapse);
      setShowControls(needToCollapse);
    }
  }, []);

  const handleShowMore = (): void => {
    setIsCollapsed(false);
    setShowControls(false);
  };

  if (isCoursesSeoHidden) return null;

  return (
    <S.CoursesSeo>
      <S.CoursesSeoWrapper withImage={!!coursesContext.seo.image}>
        <SeoTextImageContainer image={coursesContext.seo.image} />
        <S.CoursesSeoTextOuterWrapper>
          <S.CoursesSeoTextInnerWrapper
            ref={ref}
            withImage={!!coursesContext.seo.image}
            isCollapsed={isCollapsed}
          >
            <S.CoursesSeoText>
              <SeoTextContainer html={seoText!} />
              {showControls && <S.CoursesSeoTextShadow />}
            </S.CoursesSeoText>
          </S.CoursesSeoTextInnerWrapper>
          {showControls && (
            <SeoTextShowMoreContainer
              label={translations.catalog_seo_text_show_more}
              handleClick={handleShowMore}
            />
          )}
        </S.CoursesSeoTextOuterWrapper>
      </S.CoursesSeoWrapper>
    </S.CoursesSeo>
  );
};
