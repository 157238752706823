import React, { ReactElement } from 'react';
import { UserOrderFormContainerProps } from './userOrderForm.model';
import { FormSubscriptionContainer } from './formSubscription';
import { FormArchiveContainer } from './formArchive';
import { FormWithoutPricesContainer } from './formWithoutPrices';
import { CourseTypes } from '@lerna-core';
import { FormGranuleContainer } from './formGranule';
import { FormB2BWithoutCompensationContainer } from './formB2BWithoutCompensation';
import { FormB2BWithCompensationContainer } from './formB2BWithCompensation';
import { FormB2CContainer } from './formB2C';
import {
  hasCustomerCompensation,
  hasCustomerFullCompensation,
} from '@features/common/course';
import { useDiscountProgram } from '@features/common/discountProgram';
import { GoogleSheetLoggerProvider } from '@features/common/forms';

const UserOrderForm = ({
  translations,
  course,
  user,
  eventPosition,
  courseIndex,
}: UserOrderFormContainerProps): ReactElement => {
  const { isDiscountProgramPage } = useDiscountProgram();

  //todo вернуть КТТС course.status === CourseStatus.active
  const courseStatusIsActive = !!course.isActive;
  const isB2BUser = !!user?.career?.companyId;
  const customerFullCompensation = hasCustomerFullCompensation(course.price);
  const customerCompensation = hasCustomerCompensation(course.price);

  const withCompensation =
    customerFullCompensation ||
    customerCompensation ||
    course.isCustomCompensationAvailable;

  const showB2BWithCompensationForm =
    isB2BUser && withCompensation && !isDiscountProgramPage;
  const showB2CForm = !isB2BUser;

  if (course?.type === CourseTypes.granule) {
    return (
      <FormGranuleContainer
        course={course}
        user={user}
        translations={translations}
        eventPosition={eventPosition}
      />
    );
  }

  if (user?.career?.isListingWithBenefitsOnly) {
    return (
      <FormWithoutPricesContainer
        course={course}
        user={user}
        translations={translations}
        eventPosition={eventPosition}
        courseIndex={courseIndex}
      />
    );
  }

  if (course.subscriptionAccess && courseStatusIsActive) {
    return (
      <FormSubscriptionContainer
        course={course}
        user={user}
        translations={translations}
        eventPosition={eventPosition}
        courseIndex={courseIndex}
      />
    );
  }

  if (!courseStatusIsActive) {
    return (
      <FormArchiveContainer
        course={course}
        user={user}
        translations={translations}
        eventPosition={eventPosition}
        courseIndex={courseIndex}
      />
    );
  }

  if (showB2BWithCompensationForm) {
    return (
      <FormB2BWithCompensationContainer
        course={course}
        user={user}
        translations={translations}
        eventPosition={eventPosition}
        hasCustomerFullCompensation={customerFullCompensation}
        hasCustomerCompensation={customerCompensation}
        courseIndex={courseIndex}
      />
    );
  }

  if (showB2CForm) {
    return (
      <FormB2CContainer
        course={course}
        user={user}
        translations={translations}
        eventPosition={eventPosition}
        courseIndex={courseIndex}
        withOnlinePay={false}
      />
    );
  }

  return (
    <FormB2BWithoutCompensationContainer
      course={course}
      user={user}
      translations={translations}
      eventPosition={eventPosition}
      courseIndex={courseIndex}
    />
  );
};

export const UserOrderFormContainer = (props: UserOrderFormContainerProps) => (
  <GoogleSheetLoggerProvider user={props.user} course={props.course}>
    <UserOrderForm {...props} />
  </GoogleSheetLoggerProvider>
);
