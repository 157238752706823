import {
  CommonEventTypes,
  CourseModel,
  Nullable,
  PromoCodeErrorModel,
  PromoCodeWithErrorModel,
  Translations,
} from '@lerna-core';
import * as Yup from 'yup';
import {
  EVENT_TYPE,
  getTextMaxLengthValidationMessage,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import { FormB2CInitialValues, FormB2CValidationSchema } from './formB2C.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../userOrderForm.model';

export const getFormB2CInitialValues = (): FormB2CInitialValues => ({
  withPromoCode: false,
  promoCode: '',
  promoCodeDraft: '',
});

export function getFormB2CValidationSchema(
  translations: Translations,
  promoCode: Nullable<PromoCodeWithErrorModel>,
  discountProgramQuery?: string
): FormB2CValidationSchema {
  const validationObject = {
    withPromoCode: Yup.boolean().optional(),
  };

  if (!!discountProgramQuery) {
    Object.assign(validationObject, {
      promoCodeDraft: Yup.string()
        .when('withPromoCode', {
          is: true,
          then: (code) =>
            code
              .required(
                translations?.promo_code_is_not_applied ||
                  'Промокод не применён'
              )
              .max(255, getTextMaxLengthValidationMessage(255, translations))
              .trim(),
        })
        .test(
          'promoCode status 404',
          'Промокод не подходит',
          () => !((promoCode as PromoCodeErrorModel)?.status === 404)
        )
        .test(
          'promoCode status 410',
          'Истек срок действия промокода',
          () => !((promoCode as PromoCodeErrorModel)?.status === 410)
        ),
    });
  } else {
    Object.assign(validationObject, {
      promoCode: Yup.string()
        .when('withPromoCode', {
          is: true,
          then: (code) =>
            code
              .required(translations?.input_required_error_label)
              .max(255, getTextMaxLengthValidationMessage(255, translations))
              .trim(),
        })
        .test(
          'promoCode status 404',
          'Промокод не подходит',
          () => !((promoCode as PromoCodeErrorModel)?.status === 404)
        )
        .test(
          'promoCode status 410',
          'Истек срок действия промокода',
          () => !((promoCode as PromoCodeErrorModel)?.status === 410)
        ),
    });
  }

  return Yup.object().shape(validationObject);
}

export const getFormSubmitEventValues = (
  course: CourseModel,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean
): OrderFormEventsValues[] => {
  const eventValueOptions = [
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
  ];

  switch (paymentContent) {
    case UserOrderFormPaymentContentTypes.consultation:
      switch (true) {
        case course.isFree:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.priceNull);
          break;
        case course.isFreeByDiscount:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.discount100);
          break;
        case course.isFreeByBonuses:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.discountPoints);
          break;
        default:
          eventValueOptions.unshift(OrderFormEventsValues.paidCourse);
          eventValueOptions.push(OrderFormEventsValues.consultation);
          break;
      }
      break;
    case UserOrderFormPaymentContentTypes.onlinePay:
      switch (true) {
        case course.isFree:
          eventValueOptions.unshift(OrderFormEventsValues.freeCourse);
          eventValueOptions.push(OrderFormEventsValues.priceNull);
          break;
        default:
          eventValueOptions.unshift(OrderFormEventsValues.paidCourse);
          eventValueOptions.push(OrderFormEventsValues.paymentOnline);
          break;
      }
      break;
  }

  return eventValueOptions;
};

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = getFormSubmitEventValues(
    course,
    paymentContent,
    showPaymentTabs
  );
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    undefined,
    orderId,
    requestType
  );
};
