import styled from 'styled-components';
import { fontSettings } from '@lerna-core';

export const SeoTextShowMoreContainerStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  color: ${({ theme }): string => theme.COLOR.PRIMARY['500']};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  width: max-content;

  svg {
    transform: rotate(90deg);
    margin-left: 8px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
