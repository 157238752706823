import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Nullable, useTheme } from '@lerna-core';
import { FilterPopupStyled } from './coursesFilterPopup.styled';
import { useCoursesPageContext } from '../coursesPage';
import {
  FilterEvent,
  FiltersPopupContainer,
  getFiltersEventData,
} from '@features/common/filters';

export const CoursesFilterPopupContainer = (): Nullable<ReactElement> => {
  const { pagination, translations, filters } = useCoursesPageContext();
  const theme = useTheme();

  const filterPopupRef = useRef<Nullable<HTMLDivElement>>(null);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [initialFiltersQuery, setInitialFiltersQuery] = useState<string>('');

  const handleClose = useCallback(() => {
    setIsOpened(false);

    getFiltersEventData(
      FilterEvent.constructorFiltersFixed,
      filters,
      initialFiltersQuery
    );
  }, [filters, initialFiltersQuery]);

  const handleOpen = useCallback(() => {
    setIsOpened(true);
  }, []);

  useEffect(() => {
    if (isOpened) {
      setInitialFiltersQuery(window.location.search);
    }
  }, [isOpened]);

  return (
    <FilterPopupStyled ref={filterPopupRef}>
      <FiltersPopupContainer
        contentWrap={filterPopupRef.current}
        onClose={handleClose}
        onOpen={handleOpen}
        coursesCount={pagination.total}
        filters={filters}
        isMobile={theme.IS_MOBILE}
        translations={translations}
      />
    </FilterPopupStyled>
  );
};
