import { ReactElement } from 'react';

export const WheelArrowComponent = (): ReactElement => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 290 291">
    <path
      d="m143.799 89.54-9.831 38.502a1.268 1.268 0 0 0 .216 1.078 1.22 1.22 0 0 0 .974.485h19.661c.188 0 .373-.044.541-.128a1.243 1.243 0 0 0 .662-.871c.038-.187.033-.38-.014-.564l-9.831-38.502a1.247 1.247 0 0 0-.437-.674 1.218 1.218 0 0 0-1.503 0c-.216.17-.37.406-.438.674Z"
      fill="#001F30"
    />
    <g filter="url(#wa_a)">
      <path
        d="M145 176c16.569 0 30-13.636 30-30.458 0-16.821-13.431-30.457-30-30.457-16.569 0-30 13.636-30 30.457C115 162.364 128.431 176 145 176Z"
        fill="url(#wa_b)"
        fill-opacity=".9"
      />
    </g>
    <path
      d="M145.002 168.349c12.406 0 22.464-10.211 22.464-22.807 0-12.595-10.058-22.806-22.464-22.806-12.407 0-22.464 10.211-22.464 22.806 0 12.596 10.057 22.807 22.464 22.807Z"
      fill="url(#wa_c)"
    />
    <path
      d="M145.001 166.474c11.387 0 20.618-9.371 20.618-20.932 0-11.561-9.231-20.932-20.618-20.932-11.387 0-20.617 9.371-20.617 20.932 0 11.561 9.23 20.932 20.617 20.932Z"
      fill="url(#wa_d)"
    />
    <defs>
      <linearGradient
        id="wa_c"
        x1="129.118"
        y1="129.418"
        x2="161.369"
        y2="161.184"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset="1" stop-color="#999" />
      </linearGradient>
      <linearGradient
        id="wa_d"
        x1="130.423"
        y1="130.743"
        x2="160.024"
        y2="159.899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2F2F2" />
        <stop offset="1" stop-color="#B3B3B3" />
      </linearGradient>
      <radialGradient
        id="wa_b"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(30 0 0 30.4576 145 145.542)"
      >
        <stop />
        <stop offset=".47" />
        <stop offset=".53" stop-color="#040404" />
        <stop offset=".6" stop-color="#111" />
        <stop offset=".66" stop-color="#262626" />
        <stop offset=".73" stop-color="#444" />
        <stop offset=".8" stop-color="#6B6B6B" />
        <stop offset=".88" stop-color="#9A9A9A" />
        <stop offset=".95" stop-color="#D0D0D0" />
        <stop offset="1" stop-color="#fff" />
      </radialGradient>
      <filter
        id="wa_a"
        x=".683"
        y=".767"
        width="288.635"
        height="289.55"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          stdDeviation="57.159"
          result="effect1_foregroundBlur_16750_431"
        />
      </filter>
    </defs>
  </svg>
);
