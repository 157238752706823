import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../styles';

export const CoursesFAQ = styled.div`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 16px;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  margin-bottom: 32px;

  ${breakpoint(
    'mediumDown',
    css`
      background: transparent;
    `
  )}
`;

export const CoursesFAQTitle = styled.h2`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_40, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  margin: 0 0 32px;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
      margin-bottom: 20px;
    `
  )}
`;

export const CoursesFAQWrapper = styled.div`
  max-width: 856px;
  padding: 32px;
  margin: 0 auto;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 0;
    `
  )}
`;

export const CoursesFAQAccordion = styled.div`
  h2 {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
    font-weight: 600;
    margin: 0 0 16px;

    ${breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)}
      `
    )}
  }

  h3 {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}
    font-weight: 600;
    margin: 0 0 16px;

    ${breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
      `
    )}
  }

  b {
    font-weight: 500;
  }

  a {
    color: ${({ theme }): string => theme.COLOR.TEXT_MEDIUM_COLOR};
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    }
  }

  p,
  li {
    margin: 0 0 16px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    padding-left: 24px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-left: 12px;
      position: relative;
      margin-left: 12px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: ${({ theme }): string =>
          theme.COLOR.TEXT_MEDIUM_COLOR};
      }
    }
  }
`;
