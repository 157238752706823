import styled from 'styled-components';

import { transition } from '../../../styles';
import {
  AccordionSpoilerIconVersionModel,
  AccordionVersionModel,
} from '../accordion.model';
import {
  getAccordionSpoilerIconStyle,
  getAccordionSpoilerStyle,
} from './accordionSpoiler.mixin';

export const AccordionSpoiler = styled.div<{
  hasContent: boolean;
  version: AccordionVersionModel;
}>`
  position: relative;
  cursor: ${({ hasContent }): string => (hasContent ? 'pointer' : 'default')};

  ${({ theme, version }) => getAccordionSpoilerStyle(theme, version)}
`;

export const AccordionSpoilerIcon = styled.div<{
  isOpened: boolean;
  version: AccordionVersionModel;
  spoilerIconVersion: AccordionSpoilerIconVersionModel;
}>`
  display: flex;
  justify-content: center;
  position: absolute;
  ${transition('transform')};
  transform: rotate(${({ isOpened }): string => (isOpened ? '180' : '0')}deg);

  ${({ theme, version, spoilerIconVersion, isOpened }) =>
    getAccordionSpoilerIconStyle(theme, version, spoilerIconVersion, isOpened)}
`;
