import { QueryObject } from './object.model';
import { ParsedUrlQuery } from 'querystring';
import { logger } from '../logger/logger.service';

export class ObjectService {
  private static instance: ObjectService;

  public static getInstance(): ObjectService {
    if (!ObjectService.instance) {
      ObjectService.instance = new ObjectService();
    }

    return ObjectService.instance;
  }

  /**
   * Deleting empty object values
   * @params (object) obj - any object
   * @return (object) - object without empty values
   *
   * */
  public clearObject<T>(obj: T): T {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        this.clearObject(obj[key]);
      }
    }

    return obj;
  }

  /**
   * Checks if value is an empty object
   * @params (object) obj - any object
   * @return (boolean) - true if object is empty, false if object is not empty
   *
   * */

  public isEmptyObject<T>(obj: T): boolean {
    if (typeof obj !== 'object' || !obj || Array.isArray(obj)) {
      return true;
    }

    return Object.keys(obj).length === 0;
  }

  /**
   * Compare 2 objects
   * @params (object) obj1 - any object
   * @params (object) obj2 - any object
   * @return (boolean) - true if objects is equal, false if objects is not equal
   *
   * */
  public deepEqual<T>(_obj1: T, _obj2: T): boolean {
    if (typeof _obj1 === 'object' && typeof _obj2 === 'object') {
      const obj1 = _obj1 as object;
      const obj2 = _obj2 as object;
      const keys1 = Object.keys(obj1 as object);
      const keys2 = Object.keys(obj2 as object);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        const val1 = obj1[key as keyof typeof obj1] as object;
        const val2 = obj2[key as keyof typeof obj2] as object;
        const isObjects = val1 instanceof Object && val2 instanceof Object;
        const isNotEqual =
          (isObjects && !this.deepEqual(val1, val2)) ||
          (!isObjects && val1 !== val2);

        if (isNotEqual) {
          return false;
        }
      }

      return true;
    }

    logger.error('[ERROR]: arguments are not objects');

    return false;
  }

  /**
   * Get object from string
   * @params (string) queryStr - query search string
   * @return (object)
   * */
  public getObjectFromQueryString(queryStr: string): QueryObject {
    const obj = {} as QueryObject;

    const urlParams = new URLSearchParams(queryStr);

    urlParams.forEach((value, key) => {
      if (!!value) {
        obj[key as keyof typeof obj] = value;
      }
    });

    return obj;
  }

  /**
   * Get query string from object
   * @params (object) obj
   * @return (string)
   * */
  public getQueryStringFromObject(obj: ParsedUrlQuery): string {
    return Object.keys(obj)
      .map((key) => {
        let value = '';

        if (Array.isArray(obj[key])) {
          value = (obj[key] as string[]).join(',');
        } else {
          value = obj[key] as string;
        }

        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      })
      .join('&');
  }

  public deepFlattenToObject(obj: Record<any, any>, prefix = '') {
    return Object.keys(obj as object).reduce((acc: Record<any, any>, k) => {
      const pre = prefix.length ? prefix + '_' : '';
      if (typeof obj[k] === 'object' && obj[k] !== null) {
        Object.assign(acc, this.deepFlattenToObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }

      return acc;
    }, {});
  }
}

export const objectService = ObjectService.getInstance();
