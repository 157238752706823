import styled, { css } from 'styled-components';
import { getPolygon } from './wheelOfFortune.mixin';
import { breakpoint, fontSettings } from '@lerna-core';

export const WheelOfFortuneClose = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  cursor: pointer;
`;

export const WheelOfFortune = styled.div`
  display: flex;
  align-items: center;
  align-self: center;

  & > ${WheelOfFortuneClose} {
    display: none;
  }

  ${breakpoint(
    'mediumDown',
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      flex-direction: column;
      gap: 16px;
      padding: 36px 16px 48px;
      background-color: ${({ theme }) => theme.COLOR.PRIMARY[100]};
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      overflow: hidden;

      & > ${WheelOfFortuneClose} {
        display: flex;
      }
    `
  )}
`;

export const WheelOfFortuneWheelWrapper = styled.div`
  height: 440px;
  width: 440px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
  padding: 7px;
  position: relative;
  box-sizing: border-box;
  z-index: 2;
  flex: 0 0 auto;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    height: 290px;
    width: 290px;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${breakpoint(
    'mediumDown',
    css`
      height: 343px;
      width: 343px;

      svg {
        height: 220px;
        width: 220px;
      }
    `
  )}
`;

export const WheelOfFortuneWheel = styled.div`
  border: 22px solid ${({ theme }) => theme.COLOR.PRIMARY[500]};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  position: relative;
  margin: 0 auto;
  transform: rotate(90deg);
`;

export const FortuneSegment = styled.div<{
  color: string;
  index: number;
  angle: number;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
  transform: rotate(${({ angle, index }) => `${angle * index}deg`});
  clip-path: ${({ angle }) => getPolygon(angle)};
  z-index: ${({ index }) => index};

  span {
    position: absolute;
    left: 50%;
    top: 23%;
    transform: translate(-50%, -50%) rotate(-90deg);
    color: #000;
    border-radius: 5px;
    padding: 0 20px;
    font-weight: 600;
    text-align: center;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_12, theme.FONT)}
  }
`;

export const WheelOfFortuneContent = styled.div`
  position: relative;
  height: 300px;
  width: 600px;
  padding: 64px 24px 60px 224px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.COLOR.PRIMARY[100]};
  margin-left: -200px;
  z-index: 1;
  text-align: center;
  overflow: hidden;

  button {
    margin-top: 24px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      overflow: visible;

      & > ${WheelOfFortuneClose} {
        display: none;
      }
    `
  )}
`;

export const WheelOfFortuneTitle = styled.div`
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  margin-bottom: 8px;
  white-space: pre-wrap;
`;

export const WheelOfFortuneSubTitle = styled.div`
  position: relative;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
  white-space: pre-wrap;
`;

export const WheelOfFortuneSnowflake1 = styled.div`
  position: absolute;
  right: 52px;
  top: -25px;

  width: 110px;
  height: 72px;

  ${breakpoint(
    'mediumDown',
    css`
      right: -58px;
      bottom: 130px;
      top: unset;
    `
  )}
`;

export const WheelOfFortuneSnowflake2 = styled.div`
  position: absolute;
  left: 183px;
  top: 35px;

  width: 64px;
  height: 42px;

  ${breakpoint(
    'mediumDown',
    css`
      left: -40px;
      bottom: 70px;
      top: unset;
    `
  )}
`;

export const WheelOfFortuneBauble = styled.div`
  position: absolute;
  left: 233px;
  top: 238px;

  width: 68px;
  height: 63px;

  ${breakpoint(
    'mediumDown',
    css`
      left: 70%;
      bottom: -64px;
      top: unset;
    `
  )}
`;
