import {
  AccordionSpoilerIconVersionModel,
  AccordionVersionModel,
} from '../accordion.model';
import { css } from 'styled-components';
import { ITheme } from '../../themes';
import { breakpoint, fontSettings } from '../../../styles';

export const accordionSpoilerVerticalPadding = '24px';

export const getAccordionSpoilerStyle = (
  theme: ITheme,
  version: AccordionVersionModel
): ReturnType<typeof css> => {
  switch (version) {
    case 'default':
      return css`
        font-weight: 600;
        ${fontSettings(theme.FONT.SIZE_22, theme.FONT)};
        color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
        padding: ${accordionSpoilerVerticalPadding} 36px
          ${accordionSpoilerVerticalPadding} 0;

        ${breakpoint(
          'mediumDown',
          css`
            font-weight: 500;
            ${fontSettings(theme.FONT.SIZE_17, theme.FONT)};
          `
        )}
      `;
    case 'v2':
      return css`
        font-weight: 500;
        ${fontSettings(theme.FONT.SIZE_20, theme.FONT)};
        color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};
        padding: 24px 68px 24px 20px;

        ${breakpoint(
          'mediumDown',
          css`
            ${fontSettings(theme.FONT.SIZE_17, theme.FONT)};
          `
        )};
      `;
  }
};

export const getAccordionSpoilerIconStyle = (
  theme: ITheme,
  version: AccordionVersionModel,
  spoilerIconVersion: AccordionSpoilerIconVersionModel,
  isOpened: boolean
): ReturnType<typeof css> => {
  switch (version) {
    case 'default':
      return css`
        right: 0;
        top: calc(50% - 12px);

        svg path {
          stroke: ${isOpened
            ? theme.COLOR.TEXT_DARK_COLOR
            : theme.COLOR.TEXT_LIGHT_COLOR};
        }
      `;
    case 'v2':
      switch (spoilerIconVersion) {
        case 'v1': {
          return css`
            right: 20px;
            top: calc(50% - 16px);

            padding: 8px;
            border-radius: 50%;
            background-color: #333333;

            svg {
              height: 16px;
              width: 16px;

              path {
                stroke: ${theme.COLOR.WHITE};
              }
            }
          `;
        }
        case 'v2':
          return css`
            right: 20px;
            top: calc(50% - 16px);

            padding: 8px;
            border-radius: 50%;
            background-color: #333333;

            transform: none;

            svg {
              height: 16px;
              width: 16px;

              path {
                stroke: ${theme.COLOR.WHITE};
              }
            }
          `;
      }
  }
};
