import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { breakpoint, fontSettings } from '../../../styles';
import { FooterTopStyledProps } from './footerTop.model';

export const FooterTopStyled = styled.div<FooterTopStyledProps>`
  padding: 40px 0;
  display: flex;
  justify-content: ${({ hasFastLinks }): string =>
    hasFastLinks ? 'space-between' : 'flex-start'};

  ${({ hasFastLinks }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        ${hasFastLinks ? 'flex-wrap: wrap;' : ''}
      `
    )}

  ${breakpoint(
    'largeDown',
    css`
      justify-content: space-between;
    `
  )}

    ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      align-items: center;
      text-align: center;

      & > * {
        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    `
  )}
`;

export const FooterTopLeftColumnStyled = styled.div`
  display: flex;
  margin-right: 20px;

  ${breakpoint(
    'xlargeDown',
    css`
      flex-direction: column;
    `
  )}

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      margin-right: 0;
      align-items: center;
    `
  )}
`;

export const FooterTopLinksStyled = styled.div<FooterTopStyledProps>`
  display: flex;
  flex-direction: column;
  max-width: 310px;
  min-width: 300px;

  ${({ hasFastLinks }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        ${hasFastLinks ? 'margin-bottom: 40px;' : ''}
      `
    )}

  ${({ hasFastLinks }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        ${hasFastLinks ? 'margin-bottom: 0;' : ''}
        max-width: 250px;
        min-width: 200px;
      `
    )}
`;

export const FooterTopRightColumnStyled = styled.div<FooterTopStyledProps>`
  max-width: ${({ hasFastLinks }): string =>
    hasFastLinks ? '375px;' : '790px'};
  margin-left: ${({ hasFastLinks }): number => (hasFastLinks ? 0 : 92)}px;

  ${({ hasFastLinks }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        margin-left: 0;
        ${hasFastLinks
          ? 'max-width: 100%;'
          : 'max-width: 370px; margin-left: auto;'}
      `
    )}

  ${({ hasFastLinks }): ReturnType<typeof css> | string =>
    breakpoint(
      'largeDown',
      css`
        ${hasFastLinks ? 'max-width: 100%;' : 'margin-left: 0;'}
      `
    )}
`;

export const FooterTopTextWrapperStyled = styled.div``;

export const FooterTopTextStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.7)};

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
    `
  )}
`;
