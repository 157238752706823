import {
  AggregateResponseApiErrorModel,
  bannerAdapter,
  checkError,
  courseAdapter,
  CurrencyModel,
  customerCoursesAdapter,
  discountProgramAdapter,
  fastLinksAdapter,
  filtersAdapter,
  mapCurrencies,
  mapUser,
  mapVendors,
  ShowcaseApiModel,
  showcaseDefaultAdapter,
  ShowcaseErrorModel,
  VendorModel,
} from '@lerna-core';
import { COURSES_PAGE_AGGREGATE_REQUIRED_KEYS } from './courses.constants';
import { CoursesPageModel } from './courses.model';

export const coursesAdapter = (
  response: ShowcaseApiModel
): CoursesPageModel | ShowcaseErrorModel => {
  const { data } = response;
  const translations = data.statics?.data.translations;
  const currencies: CurrencyModel[] = mapCurrencies(
    data.currencies?.data || []
  );
  const vendors: VendorModel[] = mapVendors(data.vendors?.data || []);

  checkError(
    data as unknown as AggregateResponseApiErrorModel,
    COURSES_PAGE_AGGREGATE_REQUIRED_KEYS
  );

  const filters = data.filters?.data
    ? filtersAdapter(data.filters.data, translations)
    : [];

  //todo: вынести showcaseDefaultAdapter отдельно по каталогам (убрать из либы)
  return {
    ...showcaseDefaultAdapter(response, COURSES_PAGE_AGGREGATE_REQUIRED_KEYS),
    filters,
    fastLinks: response.data.fastlinks
      ? fastLinksAdapter(response.data.fastlinks.data)
      : null,
    user:
      !!data?.personal && !!data?.bonuses
        ? mapUser(data?.personal, data.bonuses, data?.career)
        : null,
    customerCourses: data?.me_courses
      ? customerCoursesAdapter(data?.me_courses)
      : [],
    discountProgram: data?.discount_programs
      ? discountProgramAdapter(data.discount_programs)
      : null,
    banners: data?.banners ? data.banners.data.map(bannerAdapter) : null,
    selectedCourse: data?.selected_course
      ? courseAdapter(
          data.selected_course.data,
          currencies,
          translations,
          vendors
        )
      : null,
  };
};
