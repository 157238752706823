import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '../../../hooks';
import { PRESETS, urlService } from '../../../services';
import { Nullable, QueryParams } from '../../../models';
import { IframeInitiator } from '../iframe.model';
import { mainPath, projectSubDomains } from '../../../constants';
import { UserAvatarContainer, UserAvatarSize, useUser } from '../../user';
import { useTranslations } from '../../translations';
import { LogoContainer } from '../../logo';
import { MenuSwitcherContainer } from '../../menu';
import { getMenuSwitcherLinks } from './iHeader.helper';
import * as S from './iHeader.styled';
import { IHeaderModel } from './iHeader.model';

export const IHeaderContainer = ({
  headerBreadcrumbs = null,
}: IHeaderModel): Nullable<ReactElement> => {
  const { IS_MOBILE } = useTheme();
  const router = useRouter();
  const logoPreset = IS_MOBILE ? 'logoCompanySmall' : 'logoCompany';
  const logoSizes = {
    height: PRESETS[logoPreset].opts.h,
    width: PRESETS[logoPreset].opts.w,
  };
  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;
  const coursesPath = mainPath.iframeCourses.replace(
    `[${QueryParams.initiator}]`,
    iframeInitiator
  );
  const { user } = useUser();
  const translations = useTranslations();
  const switcherLinks = getMenuSwitcherLinks(
    coursesPath,
    translations,
    user?.courses
  );

  if (!user) {
    return null;
  }

  return (
    <>
      <S.IHeader>
        <S.IHeaderTop>
          <S.IHeaderLogo>
            <LogoContainer
              href={urlService.getFullUrlFromToPage(coursesPath)}
              logo={user?.company?.logo}
              preset={logoPreset}
              {...logoSizes}
            />
          </S.IHeaderLogo>
          <MenuSwitcherContainer
            hiddenAfter="largeDown"
            links={switcherLinks}
          />
          <UserAvatarContainer
            personal={user!.personal}
            size={UserAvatarSize.xmedium}
            isRound
            pointer
            handleClick={() => {
              router.push(
                urlService.getFullUrlToSubDomain(
                  projectSubDomains.personal,
                  coursesPath
                )
              );
            }}
          />
        </S.IHeaderTop>
      </S.IHeader>
      <S.IHeaderBreadcrumbs>{headerBreadcrumbs}</S.IHeaderBreadcrumbs>
    </>
  );
};
