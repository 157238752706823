import React, { ReactElement, useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Image from 'next/image';
import {
  ButtonColor,
  ButtonContainer,
  IconCloseComponent,
  Nullable,
  PopupContainer,
  useUser,
} from '@lerna-core';
import {
  getCanShowPopup,
  getSegments,
  setWheelCookie,
} from './wheelOfFortune.helper';
import * as S from './wheelOfFortune.styled';
import { WheelOfFortuneClose } from './wheelOfFortune.styled';
import { FortuneSegmentModel } from './wheelOfFortune.model';
import { WheelArrowComponent } from './wheelArrow.component';
import { wheelOfFortuneClientProvider } from './wheelOfFortune.clientProvider';

export const WheelOfFortuneContainer = (): Nullable<ReactElement> => {
  const { user } = useUser();
  const [isPopupOpened, setPopupOpened] = useState<boolean>(false);
  const [rotating, setRotating] = useState<boolean>(false);
  const [rotationCount, setRotatingCount] = useState<number>(0);
  const [rotAngle, setRotAngle] = useState<number>(0);
  const animate = useAnimation();
  const segments = getSegments();
  const segmentsCount = segments.length;
  const angle = 360 / segmentsCount;

  const handleClose = () => {
    setPopupOpened(false);
  };

  const getSegmentFromAngle = (angle: number): FortuneSegmentModel => {
    const finAngle = 360 / segmentsCount;

    const finSegment = (angle - finAngle / 2) / finAngle;
    const ind = Math.floor(finSegment) + 1;

    return ind < segmentsCount ? segments[ind] : segments[0];
  };

  const handleRotateComplete = (angle: number): void => {
    const winSegment = getSegmentFromAngle(angle % 360);
    setRotating(false);
    setRotatingCount(rotationCount + 1);
    setWheelCookie(true);

    wheelOfFortuneClientProvider(winSegment.id);
  };

  useEffect(() => {
    if (rotating) {
      let angle =
        Math.round(Math.random() * 4) * 360 + Math.floor(Math.random() * 360);

      if (Math.abs(rotAngle - angle) < 1080) {
        angle = rotAngle + angle + 1080;
      }

      setRotAngle(angle);

      animate.start({
        transform: `rotate(-${angle}deg)`,
        transition: {
          duration: 7,
          ease: 'easeIn',
          type: 'spring',
          onComplete: handleRotateComplete.bind(null, angle),
        },
      });
    }

    return animate.stop;
  }, [rotating]);

  useEffect(() => {
    if (getCanShowPopup(user)) {
      setTimeout(() => {
        setPopupOpened(true);
      }, 3000);
    }
  }, []);

  return (
    <PopupContainer
      customStyle
      fullHeightMobile={false}
      hasClose={false}
      handleClose={handleClose}
      colorMode="transparent"
      isOpened={isPopupOpened}
      withoutContentOverflowY
    >
      <S.WheelOfFortune>
        <WheelOfFortuneClose onClick={handleClose}>
          <IconCloseComponent height={10} width={10} strokeWidth="3" />
        </WheelOfFortuneClose>
        <S.WheelOfFortuneWheelWrapper>
          <motion.div
            style={{ height: '100%', width: '100%' }}
            animate={animate}
          >
            <S.WheelOfFortuneWheel>
              {segments.map(({ color, text }, index) => (
                <S.FortuneSegment
                  index={index}
                  angle={angle}
                  color={color}
                  key={`segment_${index}`}
                >
                  <span>{text}</span>
                </S.FortuneSegment>
              ))}
            </S.WheelOfFortuneWheel>
          </motion.div>
          <WheelArrowComponent />
        </S.WheelOfFortuneWheelWrapper>
        <S.WheelOfFortuneContent>
          <WheelOfFortuneClose onClick={handleClose}>
            <IconCloseComponent height={10} width={10} strokeWidth="3" />
          </WheelOfFortuneClose>
          <S.WheelOfFortuneSnowflake1>
            <Image fill priority src="/img/snowflake.png" alt="snowflake" />
          </S.WheelOfFortuneSnowflake1>
          <S.WheelOfFortuneSnowflake2>
            <Image fill priority src="/img/snowflake.png" alt="snowflake" />
          </S.WheelOfFortuneSnowflake2>
          <S.WheelOfFortuneBauble>
            <Image
              fill
              priority
              src="/img/christmas_bauble.png"
              alt="christmas_bauble"
            />
          </S.WheelOfFortuneBauble>
          {rotating || rotationCount < 1 ? (
            <>
              <S.WheelOfFortuneTitle>Крутите колесо!</S.WheelOfFortuneTitle>
              <S.WheelOfFortuneSubTitle>
                Новогодние подарки ждут вас!
              </S.WheelOfFortuneSubTitle>
              <ButtonContainer
                fullWidth
                disabled={rotating}
                color={ButtonColor.purple}
                title="Получить приз"
                onClick={() => setRotating(true)}
              />
            </>
          ) : (
            <>
              <S.WheelOfFortuneTitle>
                {'Поздравляем!\nПисьмо с подарком придет вам на почту'}
              </S.WheelOfFortuneTitle>
              <S.WheelOfFortuneSubTitle>
                {
                  'Вы можете крутить колесо 1 раз в день.\nЗаглядывайте за новыми\nподарками каждый день'
                }
              </S.WheelOfFortuneSubTitle>
            </>
          )}
        </S.WheelOfFortuneContent>
      </S.WheelOfFortune>
    </PopupContainer>
  );
};
