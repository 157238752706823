import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const SeoTextImageStyled = styled.div`
  width: 240px;
  height: 240px;
  flex: 0 0 auto;
  border-radius: 16px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }

  ${breakpoint(
    'mediumDown',
    css`
      width: 140px;
      height: 140px;
    `
  )}
`;
