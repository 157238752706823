import React, { ReactElement, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonSize,
  getIsOpenedFiltersSelector,
  getSelectedFiltersCountSelector,
  handleOpenFilters,
  Nullable,
  PopupContainer,
} from '@lerna-core';
import { modalPaddings } from './filterPopup.meta';
import { FilterPopupFooterContainer } from './filterPopupFooter';
import {
  FilterPopupTitleStyled,
  FiltersPopupContainerStyled,
} from './filterPopup.styled';
import { FiltersPopupMobileContainer } from './filterPopupMobile';
import { FiltersPopupDesktopContainer } from './filtersPopupDesktop';
import { FilterPopupContainerProps } from './filterPopup.model';
import { useCoursesPageContext } from '@features/courses/coursesPage';
import { useRouter } from 'next/router';
import { getCoursesPath } from '@helpers';

export const FiltersPopupContainer = ({
  translations,
  filters,
  coursesCount,
  isMobile,
  contentWrap,
  onClose,
  onOpen,
}: FilterPopupContainerProps): Nullable<ReactElement> => {
  const dispatch = useDispatch();
  const {
    additionalColors: { filtersAccentColor },
  } = useCoursesPageContext();
  const router = useRouter();
  const coursesPath = getCoursesPath(router);
  const selectedFiltersCount = useSelector(getSelectedFiltersCountSelector);
  const isOpened = useSelector(getIsOpenedFiltersSelector);
  const hasSelected = selectedFiltersCount > 0;
  const footerButtonSize = useMemo(
    () => (isMobile ? ButtonSize.small : ButtonSize.normal),
    []
  );

  useEffect(() => {
    if (isOpened && !!onOpen) {
      onOpen();
    }
  }, [isOpened]);

  const handleClose = (): void => {
    dispatch(handleOpenFilters(false));

    if (!!onClose) {
      onClose();
    }
  };

  const widthPopupContainer = isMobile ? 'auto' : '720px';

  const Footer = hasSelected ? (
    <FilterPopupFooterContainer
      coursesCount={coursesCount}
      handleClose={handleClose}
      filters={filters}
      isMobile={isMobile}
      translations={translations}
      defaultPathname={coursesPath}
      buttonSize={footerButtonSize}
    />
  ) : null;

  return (
    <FiltersPopupContainerStyled>
      <PopupContainer
        title={translations?.filters_popup_title}
        footer={Footer}
        handleClose={handleClose}
        hasHeader={true}
        hasClose={true}
        isMobile={isMobile}
        isOpened={isOpened}
        width={widthPopupContainer}
        styleSettings={isMobile ? undefined : modalPaddings}
        parent={contentWrap}
      >
        <>
          <FilterPopupTitleStyled>
            {translations?.filters_popup_title}
          </FilterPopupTitleStyled>
          {isMobile ? (
            <FiltersPopupMobileContainer
              filters={filters}
              translations={translations}
              defaultPathname={coursesPath}
              coursesCount={coursesCount}
              parent={contentWrap}
              customFilterColor={filtersAccentColor}
            />
          ) : (
            <FiltersPopupDesktopContainer
              filters={filters}
              translations={translations}
              customFilterColor={filtersAccentColor}
            />
          )}
        </>
      </PopupContainer>
    </FiltersPopupContainerStyled>
  );
};
