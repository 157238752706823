import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { SEO_TEXT_HEIGHT } from './coursesSeo.constants';

export const CoursesSeo = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  border-radius: 16px;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  margin-bottom: 32px;
  color: ${({ theme }): string => theme.COLOR.TEXT_MEDIUM_COLOR};

  ${breakpoint(
    'mediumDown',
    css`
      ${breakpoint(
        'mediumDown',
        css`
          ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
        `
      )}
    `
  )}
`;

export const CoursesSeoWrapper = styled.div<{ withImage: boolean }>`
  max-width: ${({ withImage }) => (withImage ? '1128px' : '856px')};
  padding: 32px;
  margin: 0 auto;
  display: flex;
  gap: 32px;

  ${breakpoint(
    'mediumDown',
    css`
      padding: 20px;
      flex-direction: column;
      gap: 20px;
    `
  )}
`;

export const CoursesSeoTextOuterWrapper = styled.div``;

export const CoursesSeoTextInnerWrapper = styled.div<{
  isCollapsed: boolean;
  withImage: boolean;
}>`
  max-height: ${({ isCollapsed, withImage, theme }): string =>
    isCollapsed
      ? `${
          theme.IS_MOBILE
            ? withImage
              ? SEO_TEXT_HEIGHT.MOBILE
              : SEO_TEXT_HEIGHT.MOBILE_WITH_IMAGE
            : SEO_TEXT_HEIGHT.DESKTOP
        }px`
      : 'unset'};
  overflow: hidden;
  position: relative;
`;

export const CoursesSeoText = styled.div``;

export const CoursesSeoTextShadow = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 56px;
  background: linear-gradient(
    0deg,
    ${({ theme }): string => theme.COLOR.WHITE},
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2)
  );
`;
