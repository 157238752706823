import React, { ReactElement } from 'react';
import { ImageContainer, Nullable } from '@lerna-core';
import { SeoTextImageStyled } from './seoTextImage.styled';
import { SeoTextImageModel } from './seoTextImage.model';

export const SeoTextImageContainer = ({
  image,
}: SeoTextImageModel): Nullable<ReactElement> => {
  if (!image) return null;

  return (
    <SeoTextImageStyled>
      <ImageContainer imageResource={image.imageResource} preset="seoImage" />
    </SeoTextImageStyled>
  );
};
