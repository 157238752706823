import React, { ReactElement } from 'react';
import { AccordionSpoilerContainerProps } from './accordionSpoiler.model';
import * as S from './accordionSpoiler.styled';
import { getAccordionSpoilerIcon } from './accordionSpoiler.helper';

export const AccordionSpoilerContainer = ({
  title,
  version,
  spoilerIconVersion,
  isOpened,
  handleClickItem,
  hasContent,
}: AccordionSpoilerContainerProps): ReactElement => {
  const handleClick = (): void => {
    if (hasContent) {
      handleClickItem();
    }
  };

  return (
    <S.AccordionSpoiler
      onClick={handleClick}
      hasContent={hasContent}
      version={version}
    >
      {title}
      {hasContent && (
        <S.AccordionSpoilerIcon
          isOpened={isOpened}
          version={version}
          spoilerIconVersion={spoilerIconVersion}
        >
          {getAccordionSpoilerIcon(spoilerIconVersion, isOpened)}
        </S.AccordionSpoilerIcon>
      )}
    </S.AccordionSpoiler>
  );
};
