import { TranslationModel } from '../../../models';
import { MenuSwitcherEvents, MenuSwitcherLinkModel } from '../../menu';
import { publicConfig, urlService } from '../../../services';
import { projectSubDomains, projectTypes } from '../../../constants';

export const getMenuSwitcherLinks = (
  coursesPath: string,
  translations: TranslationModel,
  coursesCount = 0
): MenuSwitcherLinkModel[] => {
  return [
    {
      title: translations.catalog,
      href: urlService.getFullUrlFromToPage(coursesPath),
      isActive: publicConfig.APP_TYPE === projectTypes.courses,
      analyticsEvent: MenuSwitcherEvents.goToCatalog,
    },
    {
      title: translations.my_education,
      count: coursesCount,
      href: urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        coursesPath
      ),
      isActive: publicConfig.APP_TYPE === projectTypes.personal,
      analyticsEvent: MenuSwitcherEvents.goToPersonal,
    },
  ];
};
