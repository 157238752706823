import { NonRequiredString } from '../../models';

export type AccordionModel = {
  title: string;
  content: NonRequiredString;
};

export enum AccordionVersions {
  default = 'default',
  v2 = 'v2',
}

export enum AccordionSpoilerIconVersions {
  v1 = 'v1',
  v2 = 'v2',
}

export type AccordionVersionModel = keyof typeof AccordionVersions;

export type AccordionSpoilerIconVersionModel =
  keyof typeof AccordionSpoilerIconVersions;

export type AccordionContainerProps = {
  items: AccordionModel[];
  autoClose?: boolean;
  version?: AccordionVersionModel;
  spoilerIconVersion?: AccordionSpoilerIconVersionModel;
};
