import styled, { css } from 'styled-components';
import { breakpoint } from '@lerna-core';

export const HeaderBreadcrumbsWrapper = styled.div<{ hasBorder: boolean }>`
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  width: 100%;
  border-top-style: solid;
  border-top-color: ${({ theme }): string => theme.COLOR.BLACK_08};
  border-top-width: ${({ hasBorder }): string => (hasBorder ? '1px' : '0')};
`;

export const HeaderBreadcrumbs = styled.div`
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  overflow-x: auto;
  padding: 10px 40px;
  max-width: 1920px;
  margin: 0 auto;

  ${({ theme }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        padding: 10px 20px;
      `,
      theme.IS_MOBILE
    )}
`;
