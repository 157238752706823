import React, {
  memo,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import {
  FilterModel,
  FilterOptionsSearchModel,
  FilterTypeEnum,
  Nullable,
  useTheme,
} from '@lerna-core';
import { useCoursesPageContext } from '../../coursesPage';
import { FilterContainerProps } from './filter.model';
import { FilterStyled } from './filter.styled';
import {
  FilterIconContainer,
  FilterToggleContainer,
  FilterTypeItem,
  isCustomCheckboxFilter,
} from '@features/common/filters';
import { getCoursesPath } from '@helpers';

const Filter = ({ filter }: FilterContainerProps): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const router = useRouter();
  const theme = useTheme();

  const filterDropdownRef = useRef<Nullable<HTMLDivElement>>(null);

  const [title, setTitle] = useState(filter.showTitle);

  const isToggle = filter.type === FilterTypeEnum.toggle;
  const defaultPath = getCoursesPath(router);
  const searchColors: FilterOptionsSearchModel = {
    default: theme.COLOR.TEXT_LIGHT_COLOR,
    active: theme.COLOR.TEXT_DARK_COLOR,
  };

  const filterColor =
    coursesContext.additionalColors.filtersAccentColor ||
    (filter as FilterModel).activeButtonColor;
  const filterBackgroundColor = (filter as FilterModel).backgroundColor;
  const filterIconColor = (filter as FilterModel).iconColor;
  const isCustomFilter = useMemo(() => isCustomCheckboxFilter(filter.slug), []);
  const filterIcon = (
    <FilterIconContainer
      filterSlug={filter.slug}
      background={filterIconColor}
    />
  );

  useEffect(() => {
    const newTitle = filter.showTitle;

    if (newTitle !== title) {
      setTitle(newTitle);
    }
  }, [filter]);

  if (!filter.isVisible) {
    return null;
  }

  return isToggle ? (
    <FilterToggleContainer filter={filter as FilterModel} />
  ) : (
    <FilterStyled
      data-instance="filter"
      ref={filterDropdownRef}
      isCustomFilter={isCustomFilter}
      background={filterBackgroundColor}
    >
      <FilterTypeItem
        filter={filter}
        translations={coursesContext.translations}
        defaultPathname={defaultPath}
        searchColors={searchColors}
        withDescription={false}
        isCollapsed
        withTitle
        icon={filterIcon}
        filterColor={filterColor}
      />
    </FilterStyled>
  );
};

export const FilterContainer = memo(Filter);
