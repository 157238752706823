import React, { ReactElement } from 'react';
import * as S from './coursesFAQ.styled';
import { CoursesFAQModel } from './coursesFAQ.model';
import { Nullable } from '../../../models';
import { useTranslations } from '../../translations';
import { AccordionContainer } from '../../accordion';

export const CoursesFAQContainer = ({
  faqs,
}: CoursesFAQModel): Nullable<ReactElement> => {
  const translations = useTranslations();
  if (!faqs) {
    return null;
  }

  return (
    <S.CoursesFAQ>
      <S.CoursesFAQWrapper>
        <S.CoursesFAQTitle>
          {translations.course_questions_title}
        </S.CoursesFAQTitle>
        <S.CoursesFAQAccordion>
          <AccordionContainer
            version="v2"
            spoilerIconVersion="v2"
            items={faqs.map(({ question, answer }) => ({
              title: question,
              content: answer,
            }))}
          />
        </S.CoursesFAQAccordion>
      </S.CoursesFAQWrapper>
    </S.CoursesFAQ>
  );
};
