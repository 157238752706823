import { Store } from 'redux';
import { NextPageContext } from 'next';
import {
  Department,
  departmentsByCompanyClientProvider,
  publicConfig,
  QueryParams,
  requestUserSuccess,
  serverAuthorizationService,
  setAcceptLanguage,
  setShowcaseStatus,
  ShowcaseRequestProps,
  userPositionsByCompanyProvider,
  UserPositionWithOptions,
  userRedirectMiddleware,
} from '@lerna-core';
import { setSecureHeadersProps } from '@services';
import { CoursesPageWithErrorModel } from './courses.model';
import { discountProgramClientProvider } from '@features/common/discountProgram';
import { coursesPageClientProvider } from './courses.clientProvider';

export async function coursesPageServerRequest(
  store: Store,
  context: NextPageContext
): Promise<CoursesPageWithErrorModel> {
  const user = store.getState().user.user;
  const params = { ...context.query } as ShowcaseRequestProps;
  const locale = context.locale || publicConfig.DEFAULT_LOCALE;
  const discountProgramQuery = params[QueryParams.discountProgram] as string;
  params.page = `${params.page ? params.page : 1}`;
  params.lang = locale;
  store.dispatch(setShowcaseStatus({ isLoaded: false, isLoading: true }));
  const requestHeaders = serverAuthorizationService.getAuthHeaders(
    context.req,
    context.res
  );
  const secureHeaderProps = serverAuthorizationService.isAuthed()
    ? setSecureHeadersProps()
    : {};

  const requests = [] as Promise<unknown>[];

  if (discountProgramQuery) {
    requests.push(
      discountProgramClientProvider(params, discountProgramQuery, {
        ...setAcceptLanguage(locale),
        ...secureHeaderProps,
        ...requestHeaders,
      })
    );
  } else {
    requests.push(
      coursesPageClientProvider(
        params,
        {
          ...setAcceptLanguage(locale),
          ...secureHeaderProps,
          ...requestHeaders,
        },
        user
      )
    );
  }

  const companyId = user?.career?.companyId;
  const isVerified = !!user?.career?.isVerified;

  if (companyId && !isVerified) {
    requests.push(
      departmentsByCompanyClientProvider(companyId),
      userPositionsByCompanyProvider(companyId)
    );
  }

  const requestResults = await Promise.all(requests);
  const coursesData = requestResults[0] as CoursesPageWithErrorModel;
  const departments = requestResults[1] as Department[];
  const positions = requestResults[2] as UserPositionWithOptions[];

  if (coursesData.user) {
    store.dispatch(requestUserSuccess(coursesData.user));
  }
  userRedirectMiddleware(context, coursesData.user);
  store.dispatch(setShowcaseStatus({ isLoaded: true, isLoading: false }));

  const suggest = params.title as string;
  coursesData.suggest = suggest || '';

  if (context.res && coursesData.statusCode) {
    context.res.statusCode = coursesData.statusCode;
  }

  return {
    ...coursesData,
    positions,
    departments,
  };
}
