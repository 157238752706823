import { Nullable } from '../../models';
import { AppService, publicConfig } from '../../services';
import {
  MindboxEventName,
  MindboxOptionsModel,
  MindboxSites,
} from './mindbox.model';

declare global {
  interface Window {
    mindbox: (strategy: string, options: Record<string, unknown>) => void;
  }
}

export class MindboxService {
  public EventName = MindboxEventName;
  protected userEmail = '';
  protected site: MindboxSites = publicConfig.APP_NAME as MindboxSites;
  protected scriptSrc = 'https://api.mindbox.ru/scripts/v1/tracker.js';
  private static instance: MindboxService;

  public static getInstance(): MindboxService {
    if (!MindboxService.instance) {
      MindboxService.instance = new MindboxService();
    }

    return MindboxService.instance;
  }

  public get getScriptSrc(): string {
    return this.scriptSrc;
  }

  public init(options?: MindboxOptionsModel): void {
    if (options?.email) {
      this.userEmail = options.email;
    }
  }

  public getInitializeScriptContent(): string {
    return `
      (function() {
        window.PopMechanic = { watchLocation: false }; 
        mindbox = window.mindbox || function () {
          mindbox.queue.push(arguments);
        };
        mindbox.queue = mindbox.queue || [];
        mindbox('create', {
          endpointId: '${publicConfig.MINDBOX_ID}',
        })
      })();
    `;
  }

  public sendEvent(
    eventName: MindboxEventName,
    payload: Record<string, unknown>
  ): void {
    if (AppService.isClientSide && !!window.mindbox) {
      window.mindbox('async', {
        operation: eventName,
        data: {
          ...this.getBasePayload(),
          ...payload,
        },
      });
    }
  }

  public getBasePayload(): Record<string, unknown> {
    if (this.userEmail) {
      return {
        customer: {
          email: this.userEmail,
        },
      };
    }

    return {};
  }

  private getIdsPayload(id: unknown): Record<string, unknown> {
    switch (this.site) {
      case 'lerna':
        return { website: id };
      case 'skillbox':
        return { skillboxExternalSystem: id };
    }
  }

  public getGoToCoursePayload(productId: string): Record<string, unknown> {
    return {
      viewProduct: {
        product: {
          ids: this.getIdsPayload(productId),
        },
      },
    };
  }

  public getGoToSpherePayload(
    sphereId: Nullable<number>
  ): Record<string, unknown> {
    return {
      viewProductCategory: {
        productCategory: {
          ids: this.getIdsPayload(sphereId),
        },
      },
    };
  }

  public getWheelOfFortunePayload(
    value: Record<string, string>
  ): Record<string, unknown> {
    return {
      ...this.getBasePayload(),
      customerAction: {
        customFields: value,
      },
    };
  }
}

export const mindboxService = MindboxService.getInstance();
