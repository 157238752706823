import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { HtmlWrapContainerStyled } from '@features/common/htmlWrap';

export const SeoTextContainerStyled = styled.div`
  ${HtmlWrapContainerStyled} {
    > *:first-child {
      margin-top: 0;
    }
  }

  h2 {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)}
    font-weight: 600;
    margin: 0 0 16px;

    ${breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}
      `
    )}
  }

  h3 {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)}
    font-weight: 600;
    margin: 0 0 16px;

    ${breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
      `
    )}
  }

  h6 {
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    font-weight: 500;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  b {
    font-weight: 500;
  }

  a {
    color: ${({ theme }): string => theme.COLOR.TEXT_MEDIUM_COLOR};
    font-weight: 400;
    text-decoration: underline;

    &:hover {
      color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    }
  }

  p {
    margin: 0 0 16px 0;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};

    ${breakpoint(
      'mediumDown',
      css`
        ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
      `
    )}
    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin: 0 0 16px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ol {
    padding-left: 24px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      padding-left: 12px;
      position: relative;
      margin-left: 12px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: ${({ theme }): string =>
          theme.COLOR.TEXT_MEDIUM_COLOR};
      }
    }
  }
`;
