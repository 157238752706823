import styled from 'styled-components';

import { transition } from '../../../styles';
import { getAccordionContentStyle } from './accordionContent.mixin';
import { AccordionVersionModel } from '../accordion.model';

export const AccordionContentStyled = styled.div<{
  isOpened: boolean;
  version: AccordionVersionModel;
}>`
  position: relative;
  overflow: hidden;
  max-height: ${({ isOpened }): string => (isOpened ? 'unset' : '0')};
  opacity: ${({ isOpened }): string => (isOpened ? '1' : '0')};
  ${transition('max-height, opacity')};

  ${({ theme, version, isOpened }) =>
    getAccordionContentStyle(theme, version, isOpened)}
`;
