import styled from 'styled-components';
import {
  CheckboxContainerStyled,
  customizeScrollBar,
  fontSettings,
  InputLabelStyled,
  Nullable,
  transition,
} from '@lerna-core';
import {
  FilterOptionsBorderStyled,
  FilterOptionsEmptySubTitleStyled,
  FilterOptionsEmptyTitleStyled,
  FilterOptionsItemsStyled,
} from '../../../common/filters/filterOptions/filterOptions.styled';
import {
  FilterOptionsSearchMagnifierStyled,
  FilterOptionsSearchStyled,
} from '../../../common/filters/filterOptions/filterOptionsSearch/filterOptionsSearch.styled';
import { FilterTitleContainerStyled } from '../../../common/filters/filterTitle/filterTitle.styled';
import { FilterOptionStyled } from '../../../common/filters/filterOption/filterOption.styled';
import { TooltipDesktopContentStyled } from '../../../common/filters/filterTooltip/tooltipDesktop/tooltipDesktop.styled';

export const FilterContainerStyled = styled.div<{
  isCustomFilter: boolean;
  background: Nullable<string>;
}>`
  ${({ isCustomFilter, background }): string =>
    isCustomFilter
      ? `
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 32px;
    background: ${background ? `${background}` : 'transparent'};
  `
      : ''}
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    margin-bottom: 0;
    border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
  }

  input {
    background-color: ${({ theme }): string => theme.COLOR.WHITE};
    border-color: #e4e4e4;
    font-weight: 400;

    ${transition('border-color')}
    :hover,
        :focus {
      border-color: #3f2aff;
    }
  }

  ${CheckboxContainerStyled} {
    font-weight: 400;
    color: ${({ theme }): string => theme.COLOR.TEXT[100]};

    &:hover {
      color: ${({ theme }): string => theme.COLOR.CHECKBOX_DEFAULT};
    }
  }

  ${FilterOptionsItemsStyled} {
    max-height: 240px;
    border: none;
    padding: 0;
    margin: 0;
    ${customizeScrollBar()}
  }

  ${FilterTitleContainerStyled} {
    font-weight: 500;
    margin-bottom: 12px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  }

  ${FilterOptionStyled} {
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  ${FilterOptionsEmptyTitleStyled} {
    text-align: left;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    margin: 0 0 8px 0;
    padding: 0;
    font-weight: 500;
  }

  ${FilterOptionsEmptySubTitleStyled} {
    margin: 0;
    padding: 0;
    text-align: left;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  }

  ${FilterOptionsSearchStyled} {
    margin-bottom: 12px;
    position: static;

    input {
      padding-left: 16px;
    }
  }

  ${FilterOptionsSearchMagnifierStyled} {
    display: none;
  }

  ${InputLabelStyled} {
    font-weight: 400;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  }

  ${FilterOptionsBorderStyled}:not(:first-child) {
    height: 1px;
    margin-bottom: -1px;
    width: 100%;
  }

  ${TooltipDesktopContentStyled} {
    transform: translateX(-30%);

    &::before {
      left: 30%;
    }
  }
`;
