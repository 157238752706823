import { FiltersModel } from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../coursesPage';
import { FilterColumnContainer } from '../filterColumn';
import { FiltersClearContainer } from '../filtersClear';
import { FiltersColumnModel } from './filtersColumn.model';
import { FiltersColumnContainerStyled } from './filtersColumn.styled';

export const FiltersColumnContainer = ({
  withFilterDescription = true,
}: FiltersColumnModel): ReactElement => {
  const coursesContext = useCoursesPageContext();

  const filters = (coursesContext?.filters || []) as FiltersModel[];

  return (
    <>
      <FiltersColumnContainerStyled>
        {filters.map((filter) => (
          <FilterColumnContainer
            withFilterDescription={withFilterDescription}
            filter={filter}
            key={filter.slug}
          />
        ))}
      </FiltersColumnContainerStyled>
      <FiltersClearContainer
        label={coursesContext.translations.clear_filters_label}
      />
    </>
  );
};
