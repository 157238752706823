import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '@lerna-core';
import { rgba } from 'polished';

export const CoursesCount = styled.div`
  margin: 32px 0 20px;
`;

export const CoursesTitle = styled.span`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
  font-weight: 700;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
    `
  )}
`;

export const CoursesCountH1 = styled.h1`
  display: inline;
  margin: 0;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
  font-weight: 700;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
    `
  )}
`;

export const CoursesNumber = styled.span`
  margin-left: 4px;
  color: ${({ theme }): string => rgba(theme.COLOR.TEXT_DARK_COLOR, 0.4)};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
  font-weight: 700;

  ${breakpoint(
    'mediumDown',
    css`
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)};
    `
  )}
`;
