import axios from 'axios';
import { cookieService, mindboxService, publicConfig } from '@lerna-core';
import {
  getMindboxEventName,
  getMindboxEventValue,
} from './wheelOfFortune.helper';

const mindboxApiUrl = 'https://api.mindbox.ru/v3/operations/async';
const operation = getMindboxEventName();
const endpointId = publicConfig.MINDBOX_ID;

export const wheelOfFortuneClientProvider = (winId: string): Promise<void> => {
  const deviceUUID = cookieService.getCookie(
    'mindboxDeviceUUID',
    document.cookie,
    false
  );

  return axios.post(
    mindboxApiUrl,
    mindboxService.getWheelOfFortunePayload(getMindboxEventValue(winId)),
    {
      params: {
        endpointId,
        operation,
        deviceUUID,
      },
      headers: {
        Authorization: `SecretKey ${publicConfig.MINDBOX_SECRET_KEY}`,
      },
      withCredentials: true,
    }
  );
};
