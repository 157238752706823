import React, { ReactElement, useState } from 'react';

import { AccordionItemContainer } from './accordionItem';
import { AccordionContainerProps } from './accordion.model';
import { Nullable } from '../../models';

export const AccordionContainer = ({
  items,
  version = 'default',
  spoilerIconVersion = 'v1',
  autoClose,
}: AccordionContainerProps): Nullable<ReactElement> => {
  const [openedIndex, setOpenedIndex] = useState<number[]>([]);

  if (items.length === 0) {
    return null;
  }

  const checkIsOpened = (index: number): boolean => openedIndex.includes(index);

  const handleClickItem = (index: number): void => {
    if (checkIsOpened(index)) {
      setOpenedIndex(
        openedIndex.filter((currentIndex) => index !== currentIndex)
      );
    } else {
      if (autoClose) {
        setOpenedIndex([index]);
      } else {
        setOpenedIndex([...openedIndex, index]);
      }
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <AccordionItemContainer
          key={index}
          item={item}
          version={version}
          spoilerIconVersion={spoilerIconVersion}
          isOpened={checkIsOpened(index)}
          handleClickItem={handleClickItem.bind(null, index)}
        />
      ))}
    </>
  );
};
