import styled, { css } from 'styled-components';

import { fontSettings, positionSticky } from '../../../../styles';
import {
  getStylesByMode,
  ITheme,
  PopupColorModeModel,
  STICKY_HEADER_HEIGHT,
} from '../../../index';
import { PopupCloseContainerStyled, PopupStyleProps } from '../index';

type PopupHeaderStyledProps = {
  styleProps: PopupStyleProps;
  isSticky: boolean;
  colorMode: PopupColorModeModel;
};

const getStickyStyled = (theme: ITheme): ReturnType<typeof css> => css`
  opacity: 1;
  border-bottom-color: rgba(0, 0, 0, 0.08);
  z-index: ${theme.ZINDEX.TOP_BLOCK + 1};
  color: ${theme.COLOR.TEXT_DARK_COLOR};

  ${PopupCloseContainerStyled} {
    opacity: 1;
  }
`;

export const PopupHeaderContainerStyled = styled.div<PopupHeaderStyledProps>`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  ${({ theme, colorMode }): string => getStylesByMode(theme, colorMode)}
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_BLOCK + 1};
  ${positionSticky()};
  margin-bottom: -${(): number => STICKY_HEADER_HEIGHT}px;
  height: ${(): number => STICKY_HEADER_HEIGHT}px;
  top: 0;
  padding: 16px 56px;
  flex: 0 0 auto;
  box-sizing: border-box;
  color: transparent;
  border-bottom: 1px solid transparent;
  border-radius: 24px 24px 0 0;

  ${(props): ReturnType<typeof css> | '' =>
    props.isSticky ? getStickyStyled(props.theme) : ''};
`;
