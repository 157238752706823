import React, { memo, ReactElement } from 'react';
import * as S from './coursesCount.styled';
import { useCoursesPageContext } from '../coursesPage';

export const CoursesCount = (): ReactElement => {
  const { pagination, translations, seo } = useCoursesPageContext();
  const title = seo.h1 ? (
    <S.CoursesCountH1>{seo.h1}</S.CoursesCountH1>
  ) : (
    <S.CoursesTitle>{translations?.courses_found}</S.CoursesTitle>
  );

  return (
    <S.CoursesCount>
      {title}
      <S.CoursesNumber>{pagination.total}</S.CoursesNumber>
    </S.CoursesCount>
  );
};

export const CoursesCountContainer = memo(CoursesCount);
