import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import {
  filterRouterService,
  getAllFiltersWithCheckedOptions,
  pushDataLayer,
  urlService,
  useToggle,
} from '@lerna-core';
import {
  FiltersResetTypes,
  UseFiltersResetHookModel,
} from './filtersReset.model';
import { getResetItems, getResetItemsToRender } from './filtersReset.helper';
import { FILTERS_RESET_ITEMS_LIMIT } from './filtersReset.constants';
import {
  FilterEvent,
  FilterEventCategories,
  FilterEventParams,
  getFilterSimpleEventData,
} from '../filters.analytics';

export const useFiltersReset = (
  type: keyof typeof FiltersResetTypes,
  coursesPath: string
): UseFiltersResetHookModel => {
  const router = useRouter();
  const filtersWithSelectedOptions = useSelector(
    getAllFiltersWithCheckedOptions()
  );

  const isMobileType = type === FiltersResetTypes.mobile;

  const [isCollapsed, toggleCollapsed] = useToggle(false);

  const resetItems = useMemo(
    () => getResetItems(filtersWithSelectedOptions),
    [filtersWithSelectedOptions]
  );
  const resetItemsToRender = useMemo(
    () => getResetItemsToRender(resetItems, isCollapsed),
    [resetItems, isCollapsed]
  );

  const isResetItemsMaxLimit = resetItems.length > FILTERS_RESET_ITEMS_LIMIT;

  const handleClearAllFilters = useCallback((): void => {
    const newQuery = filterRouterService.clearFilters(
      filtersWithSelectedOptions,
      router
    );

    if (!isMobileType) {
      pushDataLayer(
        getFilterSimpleEventData(
          FilterEvent.constructorFiltersMain,
          FilterEventCategories.allCategory,
          FilterEventParams.allParam
        )
      );
    }

    router.push(
      urlService.createUrlFromPathAndQuery(coursesPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  }, [filtersWithSelectedOptions]);

  const showResetAllItem = isMobileType
    ? !isCollapsed || (isCollapsed && !isResetItemsMaxLimit)
    : true;
  const showShowMoreItem = isCollapsed && isResetItemsMaxLimit;

  useEffect(() => {
    if (!isCollapsed && isResetItemsMaxLimit && isMobileType) {
      toggleCollapsed();
    }
  }, []);

  return {
    filtersWithSelectedOptions,
    resetItemsToRender,
    toggleCollapsed,
    handleClearAllFilters,
    showResetAllItem,
    showShowMoreItem,
    isMobileType,
  };
};
