import React, { ReactElement } from 'react';
import { IconNextComponent, useTheme } from '@lerna-core';
import { SeoTextShowMoreContainerProps } from './seoTextShowMore.model';
import { SeoTextShowMoreContainerStyled } from './seoTextShowMore.styled';

export const SeoTextShowMoreContainer = ({
  label,
  handleClick,
}: SeoTextShowMoreContainerProps): ReactElement => {
  const theme = useTheme();

  return (
    <SeoTextShowMoreContainerStyled onClick={handleClick}>
      {label}
      <IconNextComponent strokeColor={theme.COLOR.PRIMARY[500]} />
    </SeoTextShowMoreContainerStyled>
  );
};
