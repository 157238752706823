import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  CommonEventTypes,
  getCurrentPopupFromOpen,
  logger,
  Nullable,
  PopupsEnum,
  RegisterPositions,
  requestClosePopup,
  useRegisterPopupContext,
  useToggle,
} from '@lerna-core';
import { PageNameContext } from '@contexts';
import { useUserTariff } from '@hooks';
import {
  CustomCompensationFormContextModel,
  CustomCompensationFormContextProviderProps,
} from './customCompensationForm.model';
import {
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderResponse,
  getFormOrderInfo,
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  useGoogleSheetLoggerContext,
  withPromoCodeError,
} from '@features/common/forms';
import {
  getCustomCompensationFormInitialValues,
  getCustomCompensationFormValidationSchema,
  getCustomCompensationPaymentEventValues,
  getFormSubmitEventValues,
  pushCustomCompensationFormNextButtonEvent,
  pushCustomCompensationFormPaymentEvent,
  pushFormSubmitStatusEvent,
} from './customCompensationForm.helper';
import { sendUserOrderRequest } from '../../userOrderForm.helper';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';

const CustomCompensationFormContext =
  React.createContext<Nullable<CustomCompensationFormContextModel>>(null);

export const CustomCompensationFormContextProvider = ({
  props,
  children,
}: CustomCompensationFormContextProviderProps): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { handleAction } = useRegisterPopupContext();
  const { removeNumericId } = useCourseActiveContext();
  const { googleSheetLogger } = useGoogleSheetLoggerContext();
  const pageName = useContext(PageNameContext);
  const { isLernaPrime } = useUserTariff();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const formOrderInfo = getFormOrderInfo(props.course);

  const initialPaymentContent = !formOrderInfo.hasConsultation
    ? UserOrderFormPaymentContentTypes.onlinePay
    : UserOrderFormPaymentContentTypes.consultation;

  const [paymentContent, setPaymentContent] =
    useState<UserOrderFormPaymentContentTypes>(initialPaymentContent);
  const [isCommentPopupOpened, toggleCommentPopupOpened] = useToggle(false);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const isOnlinePaySelected =
    paymentContent === UserOrderFormPaymentContentTypes.onlinePay;
  const isFreeCourse =
    props.course.isFree ||
    props.course.isFreeByBonuses ||
    props.course.isFreeByDiscount;

  const showPaymentTabs = formOrderInfo.hasOrder && !isFreeCourse;

  const validationSchema = useMemo(
    () =>
      getCustomCompensationFormValidationSchema(
        props.translations,
        isOnlinePaySelected,
        isCommentPopupOpened
      ),
    [isOnlinePaySelected, isCommentPopupOpened]
  );
  const initialValues = useMemo(
    () => getCustomCompensationFormInitialValues(),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      handleAction(
        {
          onSuccess: () => {
            actions.setSubmitting(true);
            actions.setStatus({});

            const promoCodeValue =
              !isOnlinePaySelected && values.withPromoCode
                ? values.promoCode
                : undefined;
            const withCustomCompensationValue =
              !isOnlinePaySelected && values.withCustomCompensation;
            const commentValue = isCommentPopupOpened
              ? values.comment
              : undefined;
            const withPayment = !isCommentPopupOpened && isOnlinePaySelected;

            const formEventValues = isOnlinePaySelected
              ? getCustomCompensationPaymentEventValues(showPaymentTabs)
              : getFormSubmitEventValues(
                  paymentContent,
                  showPaymentTabs,
                  values.withCustomCompensation
                );

            sendUserOrderRequest(
              props.user,
              props.course,
              router,
              true,
              withCustomCompensationValue,
              pageName,
              googleSheetLogger.bind(null, formEventValues),
              promoCodeValue,
              commentValue,
              undefined,
              withPayment
            )
              .then((response) => onSuccess(!isOnlinePaySelected, response))
              .catch((error): void => {
                const conflictError = isConflictError(error);
                const promoCodeError = withPromoCodeError(error);

                if (promoCodeError) {
                  setInputError(
                    'promoCode',
                    `${props.translations?.promocode_does_not_match}`
                  );
                  orderFormPushDataLayer(
                    OrderFormEvents.formEnrollPromoCodeError,
                    CommonEventTypes.coursesEvents,
                    props.eventPosition,
                    props.course
                  );

                  return;
                }
                if (conflictError) {
                  onExistError();

                  return;
                }

                onError();
                logger.error(
                  `[ERROR]: error sending request. ${error.response?.data}`
                );
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          },
          onError: () => {
            actions.setSubmitting(false);
          },
        },
        RegisterPositions.submitForm
      );
    },
  });

  const showFormStatus =
    (isSuccessDisplayed && !isOnlinePaySelected) ||
    isErrorDisplayed ||
    isExistOrder;
  const submitButtonTitle = values.withCustomCompensation
    ? props.translations?.forward
    : isOnlinePaySelected
    ? props.translations?.go_to_payment
    : props.translations?.send_request;

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    setFieldValue('withPromoCode', false);
    setFieldValue('promoCode', '');
    setFieldValue('withCustomCompensation', false);
    isCommentPopupOpened && toggleCommentPopupOpened();
    setFieldValue('comment', '');
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
    setPaymentContent(initialPaymentContent);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<
      B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse
    >
  ): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.success,
        props.course,
        props.eventPosition,
        paymentContent,
        showPaymentTabs,
        values.withCustomCompensation,
        (response as B2COrderResponse)?.id ||
          (response as B2BOrderResponse)?.order?.uuid ||
          (response as B2CFreeOrderResponse)?.order?.dealId,
        props.user?.career?.companyId
          ? OrderFormRequestTypes.b2b
          : OrderFormRequestTypes.b2c
      );
      if (!isOnlinePaySelected && values.withCustomCompensation) {
        orderFormPushDataLayer(
          OrderFormEvents.formEnrollCourseAddRequiredMotivationLetter,
          CommonEventTypes.coursesEvents,
          props.eventPosition,
          props.course
        );
      }
      if (values.withPromoCode) {
        orderFormPushDataLayer(
          OrderFormEvents.formEnrollPromoCode,
          CommonEventTypes.coursesEvents,
          props.eventPosition,
          props.course
        );
      }
    }

    setIsSuccessDisplayed(true);
    isCommentPopupOpened && toggleCommentPopupOpened();

    const redirect = (response as B2BOrderResponse)?.payment?.url;
    if (redirect) {
      router.push(redirect);
    }
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.error,
      props.course,
      props.eventPosition,
      paymentContent,
      showPaymentTabs,
      values.withCustomCompensation
    );
    isCommentPopupOpened && toggleCommentPopupOpened();
    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.exist,
      props.course,
      props.eventPosition,
      paymentContent,
      showPaymentTabs,
      values.withCustomCompensation
    );
    isCommentPopupOpened && toggleCommentPopupOpened();
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };
  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };
  const handleChangePaymentContent = (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ): void => {
    setPaymentContent(content as UserOrderFormPaymentContentTypes);
  };
  const handleFormSubmit = (): void => {
    if (isOnlinePaySelected) {
      pushCustomCompensationFormPaymentEvent(
        props.course,
        props.eventPosition,
        showPaymentTabs
      );
    }
    handleSubmit();
  };

  const handleFormButtonClick = (): void => {
    if (!isOnlinePaySelected && values.withCustomCompensation) {
      pushCustomCompensationFormNextButtonEvent(
        props.course,
        props.eventPosition,
        showPaymentTabs
      );

      toggleCommentPopupOpened();

      return;
    }
    handleFormSubmit();
  };

  useEffect(() => {
    if (!isOnlinePaySelected && isSubmitting && errors?.comment) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCommentError,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }
  }, [isOnlinePaySelected, isSubmitting, errors]);

  useEffect(() => {
    if (values.withCustomCompensation) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollCourseClickAddOnCompensation,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }
  }, [values.withCustomCompensation]);

  useEffect(() => {
    setPaymentContent(initialPaymentContent);
  }, [initialPaymentContent]);

  const context = {
    ...props,
    currentPopup,
    handlePopupClose,
    paymentContent,
    handleChangePaymentContent,
    submitButtonTitle,
    handleFormButtonClick,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showFormStatus,
    showPaymentTabs,
    isOnlinePaySelected,
    isFreeCourse,
    isLernaPrime,
    isCommentPopupOpened,
    toggleCommentPopupOpened,
    formOrderInfo,
  };

  return (
    <CustomCompensationFormContext.Provider value={context}>
      {children}
    </CustomCompensationFormContext.Provider>
  );
};

export const useCustomCompensationFormContext =
  (): CustomCompensationFormContextModel => {
    const value = useContext(CustomCompensationFormContext);

    if (!value) {
      logger.error('[ERROR]: you cannot use context without a provider');

      return {} as CustomCompensationFormContextModel;
    }

    return value;
  };
