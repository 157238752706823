import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  CommonEventTypes,
  getCurrentPopupFromOpen,
  logger,
  Nullable,
  PopupsEnum,
  RegisterPositions,
  requestClosePopup,
  useRegisterPopupContext,
} from '@lerna-core';
import { PageNameContext } from '@contexts';
import { useUserTariff } from '@hooks';
import {
  FormB2BWithoutCompensationContextModel,
  FormB2BWithoutCompensationContextProviderProps,
} from './formB2BWithoutCompensation.model';
import {
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderResponse,
  getFormOrderInfo,
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  useGoogleSheetLoggerContext,
  withPromoCodeError,
} from '@features/common/forms';
import {
  getB2BWithoutCompensationFormPaymentEventValues,
  getFormB2BWithoutCompensationInitialValues,
  getFormB2BWithoutCompensationValidationSchema,
  getFormSubmitEventValues,
  pushFormB2BWithoutCompensationFormPaymentEvent,
  pushFormSubmitStatusEvent,
} from './formB2BWithoutCompensation.helper';
import { sendUserOrderRequest } from '../userOrderForm.helper';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '@features/common/forms/userOrderForm/userOrderForm.model';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';

const FormB2BWithoutCompensationContext =
  React.createContext<Nullable<FormB2BWithoutCompensationContextModel>>(null);

export const FormB2BWithoutCompensationContextProvider = ({
  props,
  children,
}: FormB2BWithoutCompensationContextProviderProps): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const pageName = useContext(PageNameContext);
  const { removeNumericId } = useCourseActiveContext();
  const { googleSheetLogger } = useGoogleSheetLoggerContext();
  const { isLernaPrime } = useUserTariff();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const formOrderInfo = getFormOrderInfo(props.course);

  const initialPaymentContent = !formOrderInfo.hasConsultation
    ? UserOrderFormPaymentContentTypes.onlinePay
    : UserOrderFormPaymentContentTypes.consultation;

  const [paymentContent, setPaymentContent] =
    useState<UserOrderFormPaymentContentTypes>(initialPaymentContent);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);
  const { handleAction } = useRegisterPopupContext();

  const submitButtonTitle =
    paymentContent === UserOrderFormPaymentContentTypes.consultation
      ? props.translations?.send_request
      : props.translations?.go_to_payment;
  const isOnlinePaySelected =
    paymentContent === UserOrderFormPaymentContentTypes.onlinePay;
  const showFormStatus =
    (isSuccessDisplayed && !isOnlinePaySelected) ||
    isErrorDisplayed ||
    isExistOrder;
  const isFreeCourse =
    props.course.isFree ||
    props.course.isFreeByBonuses ||
    props.course.isFreeByDiscount;
  const showPaymentTabs = formOrderInfo.hasOrder && !isFreeCourse;

  const validationSchema = useMemo(
    () =>
      getFormB2BWithoutCompensationValidationSchema(
        props.translations,
        isOnlinePaySelected
      ),
    [isOnlinePaySelected]
  );
  const initialValues = useMemo(
    () => getFormB2BWithoutCompensationInitialValues(),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      handleAction(
        {
          onSuccess: () => {
            actions.setSubmitting(true);
            actions.setStatus({});

            const promoCodeValue =
              !isOnlinePaySelected && values.withPromoCode
                ? values.promoCode
                : undefined;

            const formEventValues = isOnlinePaySelected
              ? getB2BWithoutCompensationFormPaymentEventValues(showPaymentTabs)
              : getFormSubmitEventValues(
                  props.course,
                  paymentContent,
                  showPaymentTabs
                );

            sendUserOrderRequest(
              props.user,
              props.course,
              router,
              true,
              false,
              pageName,
              googleSheetLogger.bind(null, formEventValues),
              promoCodeValue,
              undefined,
              undefined,
              isOnlinePaySelected
            )
              .then((response) => onSuccess(!isOnlinePaySelected, response))
              .catch((error): void => {
                const conflictError = isConflictError(error);
                const promoCodeError = withPromoCodeError(error);

                if (promoCodeError) {
                  setInputError(
                    'promoCode',
                    `${props.translations?.promocode_does_not_match}`
                  );
                  orderFormPushDataLayer(
                    OrderFormEvents.formEnrollPromoCodeError,
                    CommonEventTypes.coursesEvents,
                    props.eventPosition,
                    props.course
                  );

                  return;
                }
                if (conflictError) {
                  onExistError();

                  return;
                }

                onError();
                logger.error(
                  `[ERROR]: error sending request. ${error.response?.data}`
                );
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          },
          onError: () => {
            actions.setSubmitting(false);
          },
        },
        RegisterPositions.submitForm
      );
    },
  });

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    setFieldValue('withPromoCode', false);
    setFieldValue('promoCode', '');
    setPaymentContent(initialPaymentContent);
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<
      B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse
    >
  ): void => {
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.success,
        props.course,
        props.eventPosition,
        paymentContent,
        showPaymentTabs,
        (response as B2COrderResponse)?.id ||
          (response as B2BOrderResponse)?.order?.uuid ||
          (response as B2CFreeOrderResponse)?.order?.dealId,
        props.user?.career?.companyId
          ? OrderFormRequestTypes.b2b
          : OrderFormRequestTypes.b2c
      );
    }
    if (values.withPromoCode) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollPromoCode,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course
      );
    }

    setIsSuccessDisplayed(true);

    const redirect = (response as B2BOrderResponse)?.payment?.url;
    if (redirect) {
      router.push(redirect);
    }
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.error,
      props.course,
      props.eventPosition,
      paymentContent,
      showPaymentTabs
    );
    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.exist,
      props.course,
      props.eventPosition,
      paymentContent,
      showPaymentTabs
    );
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };
  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };
  const handleChangePaymentContent = (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ): void => {
    setPaymentContent(content as UserOrderFormPaymentContentTypes);
  };
  const handleFormSubmit = (): void => {
    if (isOnlinePaySelected) {
      pushFormB2BWithoutCompensationFormPaymentEvent(
        props.course,
        props.eventPosition,
        showPaymentTabs
      );
    }
    handleSubmit();
  };

  useEffect(() => {
    setPaymentContent(initialPaymentContent);
  }, [initialPaymentContent]);

  const context = {
    ...props,
    currentPopup,
    handlePopupClose,
    paymentContent,
    handleChangePaymentContent,
    submitButtonTitle,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showFormStatus,
    showPaymentTabs,
    isOnlinePaySelected,
    isFreeCourse,
    isLernaPrime,
    formOrderInfo,
  };

  return (
    <FormB2BWithoutCompensationContext.Provider value={context}>
      {children}
    </FormB2BWithoutCompensationContext.Provider>
  );
};

export const useFormB2BWithoutCompensationContext =
  (): FormB2BWithoutCompensationContextModel => {
    const value = useContext(FormB2BWithoutCompensationContext);

    if (!value) {
      logger.error('[ERROR]: you cannot use context without a provider');

      return {} as FormB2BWithoutCompensationContextModel;
    }

    return value;
  };
