import { FastLinkApiModel, FastLinkModel } from './fastLinks.model';

export const fastLinkAdapter = (
  fastLinkApi: FastLinkApiModel
): FastLinkModel => ({
  title: fastLinkApi.title,
  slug: fastLinkApi.slug,
  options: fastLinkApi.options,
  retailRocketId: fastLinkApi.retail_rocket_id,
  mindboxId: fastLinkApi.mindbox_id,
  isVisible: !!fastLinkApi.is_visible,
});

export const fastLinksAdapter = (
  fastLinksApi: FastLinkApiModel[]
): FastLinkModel[] => fastLinksApi.map(fastLinkAdapter);
