import React, { ReactElement } from 'react';
import { AccordionContentContainer } from '../accordionContent';
import { AccordionSpoilerContainer } from '../accordionSpoiler';
import { AccordionItemStyled } from './accordionItem.styled';
import { AccordionItemContainerProps } from './accordionItem.model';

export const AccordionItemContainer = ({
  item,
  version,
  isOpened,
  spoilerIconVersion,
  handleClickItem,
}: AccordionItemContainerProps): ReactElement => {
  const hasContent = !!item.content;

  return (
    <AccordionItemStyled version={version}>
      <AccordionSpoilerContainer
        version={version}
        spoilerIconVersion={spoilerIconVersion}
        title={item.title}
        isOpened={isOpened}
        hasContent={hasContent}
        handleClickItem={handleClickItem}
      />
      {hasContent && (
        <AccordionContentContainer
          version={version}
          content={item.content}
          isOpened={isOpened}
        />
      )}
    </AccordionItemStyled>
  );
};
