import { AccordionVersionModel } from '../accordion.model';
import { css } from 'styled-components';
import { ITheme } from '../../themes';
import { breakpoint, fontSettings } from '../../../styles';

export const getAccordionContentStyle = (
  theme: ITheme,
  version: AccordionVersionModel,
  isOpened: boolean
): ReturnType<typeof css> => {
  switch (version) {
    case 'default':
      return css`
        padding-bottom: ${isOpened ? '32' : '0'}px;

        ${breakpoint(
          'mediumDown',
          css`
            margin-top: ${isOpened ? '-16' : '0'}px;
          `
        )}
      `;
    case 'v2':
      return css`
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: ${isOpened ? '20' : '0'}px;
        margin-top: ${isOpened ? '-4' : '0'}px;
        color: ${theme.COLOR.TEXT_MEDIUM_COLOR};
        ${fontSettings(theme.FONT.SIZE_17, theme.FONT)};

        ${breakpoint(
          'mediumDown',
          css`
            ${fontSettings(theme.FONT.SIZE_15, theme.FONT)};
          `
        )};
      `;
  }
};
