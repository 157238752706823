import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    PopMechanic?: {
      update?: () => void;
    };
  }
}

export const usePageRouter = (): boolean => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleStartLoading = (...args: any[]) => {
    window.PopMechanic &&
      window.PopMechanic.update &&
      window.PopMechanic.update();

    if (args?.[1]?.shallow) return;
    setIsLoading(true);
  };
  const handleEndLoading = () => {
    setIsLoading(false);
  };

  useEffect((): (() => void) => {
    router.events.on('routeChangeStart', handleStartLoading);
    router.events.on('routeChangeComplete', handleEndLoading);
    router.events.on('routeChangeError', handleEndLoading);

    return (): void => {
      router.events.off('routeChangeStart', handleStartLoading);
      router.events.off('routeChangeComplete', handleEndLoading);
      router.events.off('routeChangeError', handleEndLoading);
    };
  }, [router]);

  return isLoading;
};
