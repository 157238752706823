import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedFiltersCountSelector, Nullable } from '@lerna-core';
import { FiltersClearContainerProps } from './filtersClear.model';
import { FiltersClearContainerStyled } from './filtersClear.styled';
import { FiltersClearAllContainer } from '@features/common/filters';

export const FiltersClearContainer = ({
  label,
}: FiltersClearContainerProps): Nullable<ReactElement> => {
  const selectedFiltersCount = useSelector(getSelectedFiltersCountSelector);

  if (selectedFiltersCount < 1) {
    return null;
  }

  return (
    <FiltersClearContainerStyled>
      <FiltersClearAllContainer>
        <>{label}</>
      </FiltersClearAllContainer>
    </FiltersClearContainerStyled>
  );
};
