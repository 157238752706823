import React, { useContext } from 'react';
import { FastLinkModel } from './fastLinks.model';
import { Nullable } from '../../models';
import { logger } from '../../services';

export const FastLinksContext =
  React.createContext<Nullable<FastLinkModel[]>>(null);

export const useFastLinksContext = (): FastLinkModel[] => {
  const value = useContext(FastLinksContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
    // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
    return [] as FastLinkModel[];
  }

  return value;
};
